import styled from "styled-components";
import { ColorPalette } from "../Colors";

const nullProp = null;

export const SmallHeaderOpenSansBold = styled.p`
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "Open Sans")};
  font-weight:Bold;
  color: ${(props) => (props.color ? props.color : "black")};
  margin: ${(props) => (props.margin ? props.margin : "5px")};
  display: ${(props) => (props.display ? props.display : "block")};
  border-radius: 3px;
  transition: color 0.5s;
  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : "")};
    background-color: ${(props) =>
      props.hoverbgcolor ? props.hoverbgcolor : ""};
    border: ${(props) => (props.hoverborder ? props.hoverborder : "")};
  }
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "12px"};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
    border-bottom: ${(props) =>
      props.mobileborderbottom ? props.mobileborderbottom : ""};
  }
`;

export const SmallHeaderOpenSans = styled.p`
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "Open Sans")};
  color: ${(props) => (props.color ? props.color : "black")};
  margin: ${(props) => (props.margin ? props.margin : "5px")};
  display: ${(props) => (props.display ? props.display : "block")};
  border-radius: 3px;
  transition: color 0.5s;
  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : "")};
    background-color: ${(props) =>
      props.hoverbgcolor ? props.hoverbgcolor : ""};
    border: ${(props) => (props.hoverborder ? props.hoverborder : "")};
  }
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "12px"};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
    border-bottom: ${(props) =>
      props.mobileborderbottom ? props.mobileborderbottom : ""};
  }
`;

export const MediumHeaderMarginLeft = styled.p`
  font-size: 16px;
  color: black;
  margin-right: 40px;
  font-family: Avenir;
`;

export const Futura25PercentWidth = styled.p`
  font-family: Futura;
  width: 25%;
  display: ${(props) => (props.displayProp ? props.displayProp : "block")};
  text-decoration: ${(props) => (props.txtLine ? props.txtLine : "none")};
`;

export const RegularTextBoldRed = styled.p`
  font-size: 18px;
  font-family: Avenir;
  font-weight: bold;
  display: ${(props) => (props.display ? props.display : "")};
  color: red;
`;

//League Headers

export const LeagueSpartanLarge = styled.p`
  font-size: ${(props) => (props.fontsize ? props.fontsize : "20px")};
  margin-top: 5px;
  margin-bottom: 0px;
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  font-weight: bold;
  color: black;
  font-family: LeagueSpartan-Bold;
  white-space: ${(props) => (props.whitespace ? props.whitespace : "nowrap")};
  @media (max-width: 1006px) {
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "20px"};
  }
`;

export const LeagueSpartanRegular = styled.p`
  font-weight: bold;
  color: black;
  font-family: LeagueSpartan-Bold;
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  display: ${(props) => (props.display ? props.display : "block")};
`;

export const LeagueSpartanRegularMinWidth = styled.p`
  font-weight: bold;
  color: black;
  font-family: LeagueSpartan-Bold;
  white-space: nowrap;
  width: ${(props) => (props.width ? props.width : "")};
`;

export const LeagueSpartanExtraLargeBold = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: black;
  font-family: LeagueSpartan-Bold;
  margin-top: ${(props) => (props.margintop ? props.margintop : "5px")};
  margin-bottom: ${(props) =>
    props.marginbottom ? props.marginbottom : "5px"};
`;

export const LeagueSpartanExtraLargeWidth200px = styled.p`
font-size:24px
font-weight:bold;
color:black;
font-family: LeagueSpartan-Bold;
width:200px;
margin-left:20px;
`;

export const SmallHeaderLeagueSpartanWidth100Percent = styled.p`
  width: ${(props) => (props.width ? props.width : "100%")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "18px")};
  font-family: ${(props) =>
    props.fontfamily ? props.fontfamily : "LeagueSpartan-Bold"};
  color: ${(props) => (props.color ? props.color : "black")};
  display: ${(props) => (props.display ? props.display : "block")};
  margin-left: 2%;
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "16px"};
  }
`;

export const LeagueFontSize100Percent = styled.p`
  font-family: LeagueSpartan-Bold;
  font-size: 100%;
  cursor: pointer;
  @media (max-width: 1006px) {
    font-size: 100%;
  }
`;

export const LeagueSpartanTiny = styled.label`
  font-family: LeagueSpartan-Bold;
  font-size: 12px;
  width: ${(props) => (props.width ? props.width : "")};
`;

export const LeagueSpartanTinyMargins = styled.label`
  font-family: LeagueSpartan-Bold;
  font-size: 12px;
  white-space: pre-wrap;
  margin-top: auto;
  margin-bottom: auto;
  display: ${(props) => (props.display ? props.display : "block")};
`;

export const LeagueSpartanHeaderSmall = styled.p`
  width: ${(props) => (props.width ? props.width : "auto")};
  min-width: ${(props) => (props.minwidth ? props.minwidth : "")};
  max-width: ${(props) => (props.maxwidth ? props.maxwidth : "")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: ${(props) =>
    props.fontfamily ? props.fontfamily : "LeagueSpartan-Bold"};
  color: ${(props) => (props.color ? props.color : "black")};
  margin: ${(props) => (props.margin ? props.margin : "0px")};
  // margin-right:10px;
  white-space: ${(props) => (props.whitespace ? props.whitespace : "")};

  display: ${(props) => (props.display ? props.display : "block")};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "14px"};
  }
`;

export const MediumHeader100PercentWidthWhiteText = styled.p`
  width: ${(props) => (props.width ? props.width : "100%")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "16px")};
  font-family: ${(props) =>
    props.fontfamily ? props.fontfamily : "LeagueSpartan-Bold"};
  color: ${(props) => (props.color ? props.color : "white")};
  margin: ${(props) => (props.margin ? props.margin : "5px")};
  display: ${(props) => (props.display ? props.display : "block")};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "16px"};
  }
`;

export const MobileOnlyRegularHeader = styled.p`
  display: none;
  @media (max-width: 1006px) {
    font-family: LeagueSpartan-Bold;
    color: black;
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "18px"};
  }
`;

export const RegularTextWithMargins = styled.p`
  margin-left: 2.5%;
  margin-right: 2.5%;
  font-size: 18px;
  font-family: Avenir;
`;

export const ExtraLargeBoldText = styled.p`
  font-family: Avenir;
  font-size: 20px;
  font-weight: bold;
  @media (max-width: 1006px) {
    font-size: 100%;
  }
`;

export const RegularGreenTextDisplayBlock = styled.p`
  font-size: 18px;
  color: ${ColorPalette.PrimaryButtonBlue};
  text-align: center;
`;

export const RegularGreenText = styled.p`
  font-size: 18px;
  color: ${ColorPalette.PrimaryButtonBlue};
  text-align: center;
  @media (max-width: 1006px) {
    display: none;
  }
`;

export const RegularText = styled.p`
  font-family: Avenir;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "18px")};
  text-decoration: ${(props) => (props.txtLine ? props.txtLine : "none")};
  display: ${(props) => (props.display ? props.display : "block")};
  color: ${(props) => (props.color ? props.color : "black")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  @media (max-width: 1006px) {
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "18px"};
  }
`;

export const TinyTextItalics = styled.p`
  font-family: Avenir;
  font-size: 13px;
  max-width: ${(props) => (props.maxwidth ? props.maxwidth : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "5px")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "5px")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "x")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  display: ${(props) => (props.display ? props.display : "block")};
  color: ${(props) => (props.color ? props.color : "Black")};
  font-style: italic;
`;

export const GreyTinyTextItalics = styled.p`
  font-family: Avenir;
  font-size: 13px;
  font-weight: Bold;
  max-width: ${(props) => (props.maxwidth ? props.maxwidth : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  display: ${(props) => (props.display ? props.display : "block")};
  color: ${(props) => (props.color ? props.color : ColorPalette.BoxShadowGrey)};
  font-style: italic;
`;

export const RegularTextBold = styled.p`
  font-family: Avenir;
  font-size: 18px;
  font-weigth: bold;
  @media (max-width: 1006px) {
    font-size: 100%;
  }
`;

export const SmallTitleBold = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : "black")};
  font-family: Avenir;
  cursor: pointer;
`;

export const SmallTitleBoldWithWidth = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: black;
  font-family: Avenir;
  cursor: pointer;
  min-width: 150px;
`;

export const RegularTextSmallWithWidth = styled.p`
  font-size: 14px;
  color: black;
  font-family: Avenir;
  cursor: pointer;
  word-wrap: break-word;
  width: 50vw;
`;

export const RegularTextAutoMargin = styled.p`
  font-size: 14px;
  color: black;
  font-family: Avenir;
  margin-top: auto;
  margin-bottom: auto;
`;

export const TitleFontSize100Percent = styled.p`
  font-size: 100%;
  color: black;
  font-family: Avenir;
`;

export const RegularTextMobileSmall = styled.p`
  font-size: 18px;
  font-family: Avenir;
  color: ${(props) => (props.bgcolor ? props.bgcolor : "black")};
  @media (max-width: 1006px) {
    font-size: 14px;
  }
`;

export const TitleFontSize100PercentPointer = styled.p`
  font-family: Avenir;
  font-size: 100%;
  cursor: pointer;
  @media (max-width: 1006px) {
    font-size: 100%;
  }
`;

export const PropDiv = styled.div`
  display: ${(props) => (props.divdisplay ? props.divdisplay : "none")};
`;

export const BlueUnderlinePointerPara = styled.p`
  text-decoration: underline;
  display: ${(props) => (props.display ? props.display : "")};
  color: ${(props) => (props.color ? props.color : "blue")};
  cursor: pointer;
`;

export const ExtraLargeTextMobileRegular = styled.p`
  font-size: 24px;
  font-family: Avenir;
  @media (max-width: 1006px) {
    font-size: 18px;
  }
`;

export const LargeText = styled.p`
  font-size: 20px;
  font-family: Avenir;
`;

export const ExtraLargeText = styled.p`
  font-size: 24px;
  font-family: Avenir;
`;

export const LargeHeaderBold = styled.p`
  font-size: ${(props) => (props.font_size ? props.font_size : "20px")};
  cursor: pointer;
  font-family: Avenir;
  border-bottom: ${(props) => (props.borderbottom ? props.borderbottom : "")};
  margin-left: 4%;
  color: ${(props) => (props.font_color ? props.font_color : "black")};
  // transition: font-size 0.2s;
  // transition-timing-function: linear;
  transition: color 0.2s ease, border-bottom 0.2s ease, font-weight 0.1s ease;
  &:hover {
    color: ${ColorPalette.DarkGrey};
    border-bottom: 2px solid rgb(66, 197, 22, .5);
    font-weight: bold;
  }
`;

export const MediumHeader100PercentWidth = styled.p`
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: ${(props) => (props.maxwidth ? props.maxwidth : "")};
  min-width: ${(props) => (props.minwidth ? props.minwidth : "")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "16px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "Avenir")};
  color: ${(props) => (props.color ? props.color : "black")};
  margin: ${(props) => (props.margin ? props.margin : "5px")};
  display: ${(props) => (props.display ? props.display : "block")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "16px"};
  }
`;

export const SmallGreenLeagueText = styled.p`
  width: ${(props) => (props.width ? props.width : "auto")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "10px")};
  font-family: ${(props) =>
    props.fontfamily ? props.fontfamily : "LeagueSpartan-Bold"};
  color: ${(props) => (props.color ? props.color : ColorPalette.PrimaryButtonBlue)};
  margin: ${(props) => (props.margin ? props.margin : "5px")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  display: ${(props) => (props.display ? props.display : "block")};
  @media (max-width: 1006px) {
    display: ${(props) => (props.display ? props.display : "block")};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "12px"};
    width: auto;
  }
`;

export const TinyHeaderDarkGrey = styled.p`
  width: ${(props) => (props.width ? props.width : "auto")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "12px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "Avenir")};
  color: ${(props) => (props.color ? props.color : ColorPalette.DarkGrey)};
  margin: ${(props) => (props.margin ? props.margin : "0px")};
  margin-right: 10px;
  cursor: pointer;
  white-space: ${(props) => (props.whitespace ? props.whitespace : "nowrap")};
  overflow: ${(props) => (props.overflow ? props.overflow : "hidden")};
  text-overflow: ellipsis;
  display: ${(props) => (props.display ? props.display : "block")};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "12px"};
  }
`;

export const MediumHeaderDarkGrey = styled.p`
  width: ${(props) => (props.width ? props.width : "auto")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "16px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "Avenir")};
  color: ${(props) => (props.color ? props.color : "grey")};
  margin: ${(props) => (props.margin ? props.margin : "0px")};
  margin-right: 10px;
  display: ${(props) => (props.display ? props.display : "block")};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "14px"};
    padding-top: 1vh;
  }
`;

export const MediumHeader = styled.p`
  width: ${(props) => (props.width ? props.width : "auto")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "16px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "Avenir")};
  color: ${(props) => (props.color ? props.color : "black")};
  margin: ${(props) => (props.margin ? props.margin : "0px")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "10px")};
  display: ${(props) => (props.display ? props.display : "block")};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "14px"};
    padding-top: 1vh;
  }
`;

export const SmallText = styled.p`
  font-family: Avenir;
  color: ${(props) => (props.color ? props.color : "black")};
  text-decoration: ${(props) => (props.txtLine ? props.txtLine : "none")};
  display: ${(props) => (props.display ? props.display : "block")};
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const SmallTextPadding = styled.p`
  font-family: Avenir;
  color: black;
  font-size: 14px;
  padding: 2%;
`;

export const BlankTextArea = styled.textarea`
  margin-top: 10px;
  font-family: Avenir;
  font-size: 16px;
  width: 97.5%;
  // border: 1px dashed ${ColorPalette.PrimaryGrey};
  height: 100px;
  margin: 10px;
  margin-top: auto;
  margin-bottom: 4px;
  border: 1px dashed ${ColorPalette.PrimaryGrey};
  border-radius: 10px;
  padding: 10px;
  outline: none;
  resize: none;
  @media (max-width: 1006px) {
    box-shadow: 0px 4px 8px #b7b7b7;
    border-radius: 10px;
    padding: 10px;
    height: 150px;
  }
`;

export const StyledHRGreyBreak = styled.hr`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 0%;
  border: 0.5px solid ${ColorPalette.PrimaryGrey};
  display: ${(props) => (props.display ? props.display : "block")};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
  }
`;

export const StyledHRPropColour = styled.hr`
  width: ${(props) => (props.width ? props.width : "97.5%")};
  height: 0%;
  display: ${(props) => (props.display ? props.display : "block")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  border: ${(props) => (props.border ? props.border : "0.5px solid black")};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
  }
`;

export const StyledHRGreenBreak = styled.hr`
  width: 100%;
  height: 0%;
  border: 0.5px solid ${ColorPalette.PrimaryButtonBlue};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
  }
`;

export const StyledHRGreenBreakDashed = styled.hr`
  width: 100%;
  height: 0%;
  border: 0.5px dashed ${ColorPalette.PrimaryButtonBlue};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
  }
`;

export const StyledHRGreenBreakThick = styled.hr`
  width: ${(props) => (props.width ? props.width : "96.5%")};
  height: 0%;
  margin: 0;
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  display: ${(props) => (props.display ? props.display : "")};
  border: 4.5px solid ${ColorPalette.PrimaryButtonBlue};
  background-color: ${ColorPalette.PrimaryButtonBlue};
  border-radius: 20px 20px 20px 20px;

  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
  }
`;

export default nullProp;
