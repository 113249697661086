// Importing React
import React from "react";

// Importing stylesheet components
import { ColorPalette } from "../../StylesheetComps/Colors";

// Importing API functions
import { CaseStatus } from "../../API_Functions/CaseQuote_Functions";

// Importing mobile-specific stylesheet components
import { RegularTextMobileSmall } from "../../StylesheetComps/Titles";

const CaseStatusButton = ({ Status, StatusText, StatusColor }) => {
  switch (Status) {
    case CaseStatus.Drafted:
      StatusText = "Draft";
      StatusColor = ColorPalette.DarkGrey;
      break;
    case CaseStatus.Submitted:
      StatusText = "Submitted";
      StatusColor = ColorPalette.PrimaryRed;
      break;
    case CaseStatus.Accepted:
      StatusText = "Assigned";
      StatusColor = ColorPalette.PrimaryButtonBlue;
      break;
    case CaseStatus.Processed:
      StatusText = "Estimated";
      StatusColor = ColorPalette.PrimaryButtonBlue;
      break;
    case CaseStatus.Closed:
      StatusText = "Closed";
      StatusColor = ColorPalette.DarkGrey;
      break;
    case CaseStatus.PayPending:
      StatusText = "Pending Deposit";
      StatusColor = ColorPalette.PrimaryYellow;
      break;
    case CaseStatus.Construction:
      StatusText = "In-Progress";
      StatusColor = ColorPalette.PrimaryOrange;
      break;
    case CaseStatus.CancelRequested:
      StatusText = "Cancel Requested";
      StatusColor = ColorPalette.PrimaryRed;
      break;
    case CaseStatus.Canceled:
      StatusText = "Cancelled";
      StatusColor = ColorPalette.PrimaryRed;
      break;
    default:
      StatusText = "Draft";
      StatusColor = ColorPalette.DarkGrey;
  }

  return (
    <RegularTextMobileSmall bgcolor={StatusColor}>
      {" "}
      {StatusText}{" "}
    </RegularTextMobileSmall>
  );
};

CaseStatusButton.defaultProps = {};

export default CaseStatusButton;
