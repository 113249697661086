/* eslint-disable */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/ActionMenu";
import { useParams } from "react-router-dom";
import {
  FlexRowCont100PercentSpaceBetween,
  FlexColumnCont100xAutoPercent,
} from "../../StylesheetComps/Cont";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserRole } from "../../StylesheetComps/UserRoles";
import {
  FlexColumnFullWidth,
  LeftCont,
  FlexColumn81vwLightGrey,
} from "../../StylesheetComps/Cont";
import { Cont } from "../../StylesheetComps/Projects";
import { ContentCont77vw } from "../../StylesheetComps/Projects";
import { Icon100x100 } from "../../StylesheetComps/Imgs/Icons";
import { SmallText } from "../../StylesheetComps/Titles";
import { FlexColumnContBoxShadow } from "../../StylesheetComps/Menu";
import {
  Image300x200,
  SmallHeaderLeagueSpartan,
} from "../../StylesheetComps/LandscapeForm";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { CreateDepositRecord } from "../../API_Functions/Utility_Functions";
const PaymentSuccessScreen = ({ onPreview, onProfileUpdated }) => {
  //=============================================================================
  // Payment Success Screen - User redirected here on Successful Stripe Payment
  //=============================================================================

  //=============================================================================
  // User will redirect to QuoteAppointment Page to Book Appointment with Inst.
  //=============================================================================

  const [roleNum, setRoleNum] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [showDepositInfo, setShowDepositInfo] = useState("none");

  const history = useHistory();
  const params = useParams();

  const D = new Date();
  const currentYear = D.getFullYear();
  const currentMonth = ("0" + (D.getMonth() + 1)).slice(-2);
  const currentDay = ("0" + D.getDate()).slice(-2);
  const currentHour = ("0" + D.getHours()).slice(-2);
  const currentMinute = ("0" + D.getMinutes()).slice(-2);

  // const GetProfileInfo = async () => {
  //   sessionStorage.setItem("token", params.token);
  //   axios.defaults.baseURL = window.$DBURL;
  //   axios.defaults.headers.common["Authorization"] = "Basic " + params.token;
  //   const resp = await axios.get(window.$DBURL + "users/profile");
  //   sessionStorage.setItem("userInfo", JSON.stringify(resp.data.user));
  //   setUserInfo(JSON.parse(sessionStorage.getItem("userInfo")));
  //   setRoleNum(resp.data.user.Role);
  //   if (params.invoiceid === 0 || params.invoiceid === "0")
  //     setShowDepositInfo("flex");
  // };

  // // Create deposit transaction record and inform yardhack managers
  // const CreateDepositTransactionRecord = async () => {
  //   var CardPayRequest = {
  //     Quote_Id: params.quoteId,
  //     Store_Id: params.storeId,
  //     Amt: params.amount,
  //     TransTime:
  //       currentYear +
  //       "-" +
  //       currentMonth +
  //       "-" +
  //       currentDay +
  //       "T" +
  //       currentHour +
  //       ":" +
  //       currentMinute,
  //     TransactionId: params.transactionid,
  //     CustomerCreditCardInfo: {
  //       CreditCardType: "",
  //       CardNumber: "",
  //     },
  //     CurrencyCode: params.currency,
  //     AuthToken: params.token,
  //   };
  //   var deposit = await CreateDepositRecord(CardPayRequest);
  //   if (deposit !== null) {
  //   }
  // };
  // const ProcessSuccessPay = async () => {
  //   await GetProfileInfo();
  //   //await CreateDepositTransactionRecord()
  // };
  // useEffect(() => {
  //   // SetHeaderAuthorization();
  //   ProcessSuccessPay();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (roleNum !== UserRole.Loading) {
    return (
      <Cont>
        <LeftCont>
          {/* <Menu> </Menu> */}
        </LeftCont>
        <FlexColumn81vwLightGrey>
          {/* <ActionMenu></ActionMenu> */}

          <ContentCont77vw>
            <FlexColumnFullWidth display="block" height="auto" margin="0">
              <FlexColumnCont100xAutoPercent>
                <Image300x200 src={"/Watering_Icon.svg"}></Image300x200>
                <SmallHeaderLeagueSpartan fontsize="24px">
                  Payment Successful! Thank you!
                </SmallHeaderLeagueSpartan>

                <br></br>
                <br></br>

                <FlexRowCont100PercentSpaceBetween
                  display={'flex'}
                  mobileflexdirection="column"
                >
                  <FlexColumnContBoxShadow>
                    <Icon100x100 src="/number1.svg"></Icon100x100>
                    <SmallHeaderLeagueSpartan>
                      Schedule Project
                    </SmallHeaderLeagueSpartan>
                    <SmallText>
                      Once your project is scheduled, you will be contacted for
                      confirmation.
                    </SmallText>
                  </FlexColumnContBoxShadow>

                  <FlexColumnContBoxShadow>
                    <Icon100x100 src="/number2.svg"></Icon100x100>
                    <SmallHeaderLeagueSpartan>
                      Delivery {"&"} Construction
                    </SmallHeaderLeagueSpartan>
                    <SmallText>
                      Our team will deliver the materials and begin construction
                      based on the timeline.
                    </SmallText>
                  </FlexColumnContBoxShadow>

                  <FlexColumnContBoxShadow>
                    <Icon100x100 src="/number3.svg"></Icon100x100>
                    <SmallHeaderLeagueSpartan>
                      Complete Payment
                    </SmallHeaderLeagueSpartan>
                    <SmallText>
                      After completion you will receive the final invoice for
                      the project.
                    </SmallText>
                  </FlexColumnContBoxShadow>
                </FlexRowCont100PercentSpaceBetween>

                <br></br>
                <br></br>
{/* 
                <RegularResponsiveButton
                  onClick={() => {
                    history.push(
                      "/CasesPersonal/" +
                        userInfo.Metro_Id +
                        "/" +
                        userInfo.Store_Id
                    );
                  }}
                >
                  View Projects
                </RegularResponsiveButton> */}
              </FlexColumnCont100xAutoPercent>
            </FlexColumnFullWidth>
          </ContentCont77vw>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

PaymentSuccessScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default PaymentSuccessScreen;
