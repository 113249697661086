import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon30px20pxPointer } from '../../../StylesheetComps/Quote';
import { Icon40x40Percent } from '../../../StylesheetComps/Imgs/Icons';
import { GreenNavigationButton } from '../../../StylesheetComps/LandscapeForm';
import { RegularResponsiveButton } from '../../../StylesheetComps/Components';
import { ColorPalette } from '../../../StylesheetComps/Colors';

const GridOverlayContainer = styled.div`
  display: ${(props) => (props.isOpen ? 'grid' : 'none')};
  grid-template-columns: repeat(2, 1fr); /* Adjusted to 2 columns for a 2x2 grid */
  grid-gap: 16px;
  position: absolute;
  width: 17.5vw;
  height: 50vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  padding: 16px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  top: -30vh;
  left: -.5vw;
  background: rgba(0, 0, 0, 0.5);
  z-Index: 9999;
  padding: 16px;
  align-items: center;
  justify-content: center;
  @media (max-width: 1006px) {
    width: 90.5vw;
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto; /* Center each grid item */
  background: #f2f2f2;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added subtle shadow for depth */
  border-radius: 4px; /* Rounded corners for a modern look */
  padding: 30px; /* Increased padding for better spacing */
  width: calc(50% - 24px); /* Adjust width to fit in grid taking padding and gap into account */
  height: auto; /* Height can be auto to maintain aspect ratio */
  text-align: center;
`;

// Main GridOverlay functional component
const GridOverlay = ({ isOpen, onGridClick, showDialog, closeWindow }) => {

  const [isEstimateShownHere, setIsEstimateShownHere] = useState(false);

  return (
    <GridOverlayContainer isOpen={isOpen}>
      <GridItem onClick={() => onGridClick('Material')}>
        <Icon40x40Percent src='/Material_EstimateIcon.svg' size="24" />
        <span>Material</span>
      </GridItem>
      <GridItem onClick={() => onGridClick('Removal')}>
        <Icon40x40Percent src='/Removal_EstimateIcon.svg' size="24" />
        <span>Removal</span>
      </GridItem>
      <GridItem onClick={() => onGridClick('Access')}>
        <Icon40x40Percent src='/AccessArea_EstimateIcon.svg' size="24" />
        <span>Access</span>
      </GridItem>
      <GridItem onClick={() => onGridClick('Open')}>
        <Icon40x40Percent src='/Gear_Icon.svg' size="24" />
        <span>Custom</span>
      </GridItem>
      {
  window.$ISMOBILE === true ? 
    (isEstimateShownHere === false ? 
      <GridItem onClick={() => {onGridClick('ShowEstimate'); setIsEstimateShownHere(true)}}>
        <Icon40x40Percent src='/eye_show_icon.svg' size="24" />
        <span>Show Estimate</span>
      </GridItem>
      : 
      <GridItem onClick={() => {onGridClick('HideEstimate'); setIsEstimateShownHere(false)}}>
        <Icon40x40Percent src='/eye_hide_icon.svg' size="24" />
        <span>Hide Estimate</span>
      </GridItem>
    )
  : <GridItem >
  <Icon40x40Percent src='/eye_show_icon.svg' size="24" />
  <span>Placeholder (Soon)</span>
</GridItem>
}

    

      <GridItem >
        <Icon40x40Percent src='/text_icon.svg' size="24" />
        <span>Snapping (Soon)</span>
      </GridItem>
      <RegularResponsiveButton bgcolor={ColorPalette.PrimaryRed} border={'none'} marginleft="10px" onClick={()=>{closeWindow()}}>Close</RegularResponsiveButton>
      <RegularResponsiveButton border={'none'} marginleft="10px" onClick={()=>{showDialog()}}>Save Estimate</RegularResponsiveButton>
    </GridOverlayContainer>
  );
};

export default GridOverlay;
