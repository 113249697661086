// Importing React
import React from "react";

// Importing React hooks
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

// Importing stylesheet components
import { ColorPalette } from "../../StylesheetComps/Colors";
import { CaseCardCont } from "../../StylesheetComps/Projects";
import { SmallHeader } from "../../StylesheetComps/Menu";
import { GreenNavigationButton } from "../../StylesheetComps/LandscapeForm";
import {
  FlexColumnCont100xAutoPercent,
  FlexRowContPropWidth,
} from "../../StylesheetComps/Cont";

// Importing API functions
import { CaseStatus } from "../../API_Functions/CaseQuote_Functions";

// Importing mobile components
import { LeagueHeader12px } from "../../MobileComponents/Text";

// Importing custom buttons
import { LargeResponsiveButton } from "../../StylesheetComps/AuthSheet";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

const CaseCardUser = ({
  ProjectName,
  Status,
  CaseId,
  StatusText,
  StatusColor,
  Address,
  StartDate,
}) => {
  const { Id, storeId } = useParams();
  const history = useHistory();
  const projectpath = `/viewcaseuser/${Id}/${storeId}/${CaseId}`;

  switch (Status) {
    case CaseStatus.Drafted:
      StatusText = "Draft";
      StatusColor = ColorPalette.DarkGrey;
      break;
    case CaseStatus.Submitted:
      StatusText = "Submitted";
      StatusColor = ColorPalette.PrimaryRed;
      break;
    case CaseStatus.Accepted:
      StatusText = "Assigned";
      StatusColor = ColorPalette.PrimaryButtonBlue;
      break;
    case CaseStatus.Processed:
      StatusText = "Estimated";
      StatusColor = ColorPalette.PrimaryButtonBlue;
      break;
    case CaseStatus.Closed:
      StatusText = "Closed";
      StatusColor = ColorPalette.DarkGrey;
      break;
    case CaseStatus.PayPending:
      StatusText = "Pending Deposit";
      StatusColor = ColorPalette.PrimaryYellow;
      break;
    case CaseStatus.Construction:
      StatusText = "In-Progress";
      StatusColor = ColorPalette.PrimaryOrange;
      break;
    case CaseStatus.CancelRequested:
      StatusText = "Cancel Requested";
      StatusColor = ColorPalette.PrimaryRed;
      break;
    case CaseStatus.Canceled:
      StatusText = "Cancelled";
      StatusColor = ColorPalette.PrimaryRed;
      break;
    default:
      StatusText = "Draft";
      StatusColor = ColorPalette.DarkGrey;
  }

  if (window.$ISMOBILE === true) {
    return (
      <CaseCardCont
        onClick={() => {
          history.push(projectpath);
        }}
      >
        <FlexColumnCont100xAutoPercent>
          <LeagueHeader12px>{ProjectName}</LeagueHeader12px>
          <SmallHeader>{Address}</SmallHeader>
        </FlexColumnCont100xAutoPercent>

        <FlexColumnCont100xAutoPercent>
          <SmallHeader>{StartDate.substring(0, 10)}</SmallHeader>
          <GreenNavigationButton
            mobilemargintop="0"
            width="150px"
            mobilewidth="30vw"
            height="2.5vw"
            bgcolor={StatusColor}
          >
            {" "}
            {StatusText}{" "}
          </GreenNavigationButton>
        </FlexColumnCont100xAutoPercent>
      </CaseCardCont>
    );
  } else {
    return (
      <CaseCardCont
        onClick={() => {
          history.push(projectpath);
        }}
      >
        <FlexRowContPropWidth width="25%">
          <SmallHeader>{ProjectName}</SmallHeader>
        </FlexRowContPropWidth>

        <FlexRowContPropWidth mobiledisplay="none" width="30%">
          <SmallHeader>{Address}</SmallHeader>
        </FlexRowContPropWidth>

        <FlexRowContPropWidth mobiledisplay="none" width="35%">
          <SmallHeader>{StartDate.substring(0, 10)}</SmallHeader>
        </FlexRowContPropWidth>

        <FlexRowContPropWidth
          mobilejustifycontent="space-between"
          width="25%"
          height="100%"
        >
          <RegularResponsiveButton
            width="12.5vw"
            bgcolor={StatusColor}
          >
            {" "}
            {StatusText}{" "}
          </RegularResponsiveButton>
        </FlexRowContPropWidth>
      </CaseCardCont>
    );
  }
};

CaseCardUser.defaultProps = {
  DateText: "",
  TimeText: "",
  ClientName: "John Smith",
  Status: 0,
  StatusText: "Default",
  CaseId: 0,
  DefaultColor: "#F53838",
  StatusColor: "grey",
  EstimatorName: "Estimator",
  CaseImgs: ["name"],
  ProjectName: "Default Proj Name",
  Address: "Default Address",
  StartDate: "Default Start Date",
};

export default CaseCardUser;
