import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddQuoteItem,
  GetQuoteById,
  GetQuoteItem,
  GetQuoteTag,
  UpdateQuoteItem,
} from "../../../API_Functions/CaseQuote_Functions";
import { CategoryTypes } from "../../../StylesheetComps/CategoryTypes";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  AbsoluteCalculatorCont,
  FlexDiv,
  StickyCont400x400px,
} from "../../../StylesheetComps/Cont";
import { Div300pxMargin30px, FlexCenterDiv100PercentCenter } from "../../../StylesheetComps/Div";
import {
  DatalistOption,
  TextArea72Percent,
  TextInput72Percent,
  TextSelect72Percent,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay1000vh } from "../../../StylesheetComps/OverlayCont";
import { Cont100PercentWidth, Icon30px20pxPointer } from "../../../StylesheetComps/Quote";
import {
  LeagueSpartanRegular,
  RegularTextBoldRed,
  StyledHRGreyBreak,
  TinyTextItalics,
} from "../../../StylesheetComps/Titles";
import CalculatorWindow from "../Calculators";
import TagWindow from "../Tag";
import { GetStoreProducts } from "../../../API_Functions/CatProduct_Functions";

const QuoteItemWindow = ({
  open,
  onClose,
  onCloseNoAdd,
  onPreview,
  productobj,
  itemId,
  quoteid,
  quote,
  RefreshItemList,
  RefreshUpdatedItem,
  siteMapper,
  squareFeetSM,
  perimeterSM,
  iconCount
}) => {
  const [squareFeet, setSquareFeet] = useState(0);
  const [depth, setDepth] = useState(0);
  const [cubicFeetTotal, setCubicFeetTotal] = useState(0);
  const [taglist, setTaglist] = useState([]);
  const [calculatorsVisible, setCalculatorsVisible] = useState(false);

  //Removals
  const [removalArea, setRemovalArea] = useState(siteMapper === true ? squareFeetSM.toFixed(2) : 0);
  const [removalDepth, setRemovalDepth] = useState(0);

  const [managerNotes, setManagerNotes] = useState("");
  const [itemObj, setItemObj] = useState({});
  const [addTagWindowVisible, setAddTagWindowVisible] = useState(false);
  const [tagObj, setTagObj] = useState({});

  const [inputValue, setInputValue] = useState(0);
  const [isUserInput, setIsUserInput] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setRemovalArea(siteMapper === true ? squareFeetSM.toFixed(2) : 0);
  }, [siteMapper, squareFeetSM]);

  const params = useParams();

  const GetItem = async (itemid) => {
    try {
      const data = await GetQuoteItem(itemid);
      if (data && data.item) {
        setItemObj(data.item);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setCubicFeetTotal(squareFeet * (depth / 12));
  }, [depth, squareFeet]);

  const GetQuoteTaglist = async (quote_id) => {
    if (quote_id !== 0 && quote_id !== undefined) {
      try {
        const data = await GetQuoteById(quote_id);
        if (data && data.quote) {
          setTaglist(data.quote.Tags);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  
  useEffect(() => {
    GetQuoteTaglist(params.quoteId);
    setTaglist(quote?.Tags);
  }, [quote, productobj]);

  const ResetCalculator = async () => {
    setCubicFeetTotal(0);
    setSquareFeet(0);
    setDepth(0);
  };

  const AddNewItemToQuote = async (itemobj) => {
    onClose(true);
    try {
      const data = await AddQuoteItem(itemobj);
      if (data && data.item) {
        RefreshItemList(data.item);
        if (siteMapper === true) {
          ResetCalculator();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  
  const UpdateItemOnQuote = async (itemobj) => {
    try {
      const data = await UpdateQuoteItem(itemobj);
      if (data && data.item) {
        RefreshUpdatedItem(data.item);
        ResetCalculator();
        onClose(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  // const SaveItem = async (itemobj) => {
  //   if (itemobj) {
  //     if (itemobj.CateType === CategoryTypes.Removal) {
  //       itemObj.Quant = removalArea * (removalDepth / 12);
  //     }
  //     if (itemobj.Id === 0) {
  //       if(siteMapper === true && itemobj.CateType === CategoryTypes.Material) {
  //         setInputValue(squareFeetSM.toFixed(2));
  //         var value = (squareFeetSM.toFixed(2))
  //         itemObj.Quant = value;
  //       }
  //       AddNewItemToQuote(itemObj);
  //     } else {
  //       UpdateItemOnQuote(itemObj);
  //     }
  //   }
  // };

  const SaveItem = async (itemobj) => {
    setIsLoading(true); // Start loading
    if (itemobj) {
      if (itemobj.CateType === CategoryTypes.Removal) {
          itemObj.Quant = removalArea * (removalDepth / 12);
      }
      if (itemobj.Id === 0) {
        if(siteMapper === true && itemobj.CateType === CategoryTypes.Material && !isUserInput) {
          setInputValue(squareFeetSM.toFixed(2));
          var value = (squareFeetSM.toFixed(2))
          itemObj.Quant = value;
        }
        AddNewItemToQuote(itemObj);
      } else {
        UpdateItemOnQuote(itemObj);
      }
    }
    setInputValue(0);
    setIsLoading(false); // Stop loading once done
    setIsUserInput(false); // Reset the isUserInput state for the next input
  };

  useEffect(() => {
    if (itemId === 0) {
      itemObj.Name = productobj.Name;
      itemObj.CateType = productobj.CateType;
      itemObj.Product_Id = productobj.Id;
      itemObj.UnitPrice = productobj.UnitPrice;
      itemObj.Unit = productobj.Unit;
      itemObj.Quote_Id = params.quoteId;
    }
  }, [itemObj]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (productobj !== undefined) {
      setManagerNotes(productobj.Notes);
      GetItem(itemId);
    }
  }, [productobj]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  if (itemId === 0) {
    return (
      <>
        {" "}
        <Backdrop style={{ zIndex: 2 }} open={isLoading} onClick={()=>{setIsLoading(false)}}>
        <CircularProgress color="inherit" />
      </Backdrop>
        <FixedOverlay1000vh>
          <CalculatorWindow isVisible={calculatorsVisible} onClose={()=>{setCalculatorsVisible(false)}} squareFeetProp={squareFeetSM}></CalculatorWindow>
          <StickyCont400x400px>
            <Div300pxMargin30px>
            <FlexCenterDiv100PercentCenter display={siteMapper == true ? 'flex' : 'none'}>
              <LeagueSpartanRegular></LeagueSpartanRegular>
              <TinyTextItalics><b> Sq Ft: </b>{squareFeetSM?.toFixed(2)} sqft.</TinyTextItalics>
              <LeagueSpartanRegular></LeagueSpartanRegular>
              <TinyTextItalics> <b> Perimeter: </b>{perimeterSM?.toFixed(2)} ft.</TinyTextItalics>
              </FlexCenterDiv100PercentCenter>
              <FlexCenterDiv100PercentCenter>
              <LeagueSpartanRegular></LeagueSpartanRegular>
              <TinyTextItalics> <b> Icons Plotted: </b>{iconCount}</TinyTextItalics>
              </FlexCenterDiv100PercentCenter>
              <FlexDiv>
              <LeagueSpartanRegular>Name</LeagueSpartanRegular>
              <Icon30px20pxPointer squareFeet={squareFeetSM} src="/calculator_icon.svg" onClick={()=>{setCalculatorsVisible(true)}}></Icon30px20pxPointer>
              </FlexDiv>
              <TextInput72Percent
                defaultValue={productobj.Name}
                onChange={(e) => {
                  itemObj.Name = e.target.value;
                }}
              ></TextInput72Percent>

              <RegularTextBoldRed>{managerNotes}</RegularTextBoldRed>

              {productobj.CateType === CategoryTypes.Material ||
              productobj.CateType === CategoryTypes.Open ||
              productobj.CateType === CategoryTypes.Addon ? (
                <div>
                  <LeagueSpartanRegular>
                    Quantity ({productobj?.Unit}) of Product
                  </LeagueSpartanRegular>
                  <TextInput72Percent
                  inputMode='numeric'
                  value={inputValue}
                  key={iconCount > 0 ? iconCount : siteMapper == true ? squareFeetSM : ''}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    itemObj.Quant = e.target.value;
                    setIsUserInput(true); // User has entered input
                  }}                
                  ></TextInput72Percent>
                </div>
              ) : null}

              {productobj.CateType === CategoryTypes.Removal ? (
                <div>
                  <LeagueSpartanRegular>
                    Area (Square Feet)*
                  </LeagueSpartanRegular>
                  <TextInput72Percent
                    value={removalArea}
                    key={siteMapper === true ? squareFeetSM : ''}
                    onChange={(e) => {
                      setRemovalArea(e.target.value);
                    }}
              
                  ></TextInput72Percent>
                  <LeagueSpartanRegular>Depth (Inches)*</LeagueSpartanRegular>
                  <TextInput72Percent
                    onChange={(e) => {
                      setRemovalDepth(e.target.value);
                    }}
                  ></TextInput72Percent>
                </div>
              ) : null}

              {productobj.CateType === CategoryTypes.Labor ? (
                <div>
                  <LeagueSpartanRegular>Hours</LeagueSpartanRegular>
                  <TextInput72Percent
                    onChange={(e) => {
                      itemObj.Quant = e.target.value;
                    }}
                  ></TextInput72Percent>
                </div>
              ) : null}

              {productobj.CateType === CategoryTypes.Open ? (
                <div>
                  <LeagueSpartanRegular>Unit</LeagueSpartanRegular>
                  <TextInput72Percent
                    defaultValue={productobj.Unit}
                    onChange={(e) => {
                      itemObj.Unit = e.target.value;
                    }}
                  ></TextInput72Percent>
                  <LeagueSpartanRegular>Product Price</LeagueSpartanRegular>
                  <TextInput72Percent
                    defaultValue={productobj.UnitPrice}
                    onChange={(e) => {
                      itemObj.UnitPrice = e.target.value;
                    }}
                  ></TextInput72Percent>
                </div>
              ) : null}

              {productobj.CateType === CategoryTypes.Material ||
              productobj.CateType === CategoryTypes.Open || productobj.CateType === CategoryTypes.Removal ? (
                <div>
                <LeagueSpartanRegular>Tag</LeagueSpartanRegular>
                <FlexDiv>
                  <TextSelect72Percent
                    defaultValue={productobj.Tag_Id}
                    onChange={(e) => {
                      itemObj.Tag_Id = e.target.value;
                    }}
                  >
                    <DatalistOption value={0}>General Materials</DatalistOption>
                    {taglist?.map((o, i) => (
                      <DatalistOption value={o.Id} key={i}>
                        {o.TagName}
                      </DatalistOption>
                    ))}
                  </TextSelect72Percent>
                  <Icon30px20pxPointer onClick={async()=>{
                    var tag = await GetQuoteTag(0);
                    setTagObj(tag);
                    setAddTagWindowVisible(true)
                  }} src='/Green Button.svg'></Icon30px20pxPointer>
                </FlexDiv>
                </div>
              ) : null}
              <LeagueSpartanRegular>Notes</LeagueSpartanRegular>
              <TextArea72Percent
                onChange={(e) => {
                  itemObj.Notes = e.target.value;
                }}
              ></TextArea72Percent>

              <br></br>
              <RegularResponsiveButton
                onClick={() => {
                  SaveItem(itemObj);
                }}
              >
                Add
              </RegularResponsiveButton>

              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  if(siteMapper == true) {
                    onCloseNoAdd()
                  } else {
                    onClose(false);
                  }
                  ResetCalculator();
                }}
              >
                Cancel
              </RegularResponsiveButton>
            </Div300pxMargin30px>
          </StickyCont400x400px>
          {productobj.CateType === CategoryTypes.Material ? (
            <div>
              <AbsoluteCalculatorCont>
                <Div300pxMargin30px>
                  <LeagueSpartanRegular>
                    Square Feet to Cubic Feet
                  </LeagueSpartanRegular>

                  <StyledHRGreyBreak></StyledHRGreyBreak>

                  <LeagueSpartanRegular>Square Feet</LeagueSpartanRegular>
                  <TextInput72Percent
                    onChange={(e) => {
                      setSquareFeet(e.target.value);
                    }}
                  ></TextInput72Percent>

                  <LeagueSpartanRegular>Depth (Inches)</LeagueSpartanRegular>
                  <TextInput72Percent
                    onChange={(e) => {
                      setDepth(e.target.value);
                    }}
                  ></TextInput72Percent>
                  <Cont100PercentWidth>
                    <LeagueSpartanRegular>
                      Cubic Feet: {cubicFeetTotal.toFixed(2)}
                    </LeagueSpartanRegular>
                  </Cont100PercentWidth>
                </Div300pxMargin30px>
              </AbsoluteCalculatorCont>
            </div>
          ) : null}
          <TagWindow tagObj={tagObj}
                quoteid={params.quoteId}
                open={addTagWindowVisible}
                RefreshTaglist={() => {
                  GetQuoteTaglist(params.quoteId);
                }}
                onClose={() => {
                  setAddTagWindowVisible(false);
                }}></TagWindow>
        </FixedOverlay1000vh>{" "}
      </>
    );
  } else if (itemObj.Id === itemId) {
    ///////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////Edit Item/////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////
    return (
      <>
        {" "}
        <FixedOverlay1000vh>
          <StickyCont400x400px>
            <Div300pxMargin30px>
              <LeagueSpartanRegular>Name</LeagueSpartanRegular>
              <TextInput72Percent
                defaultValue={itemObj.Name}
                key={itemObj.Name}
                onChange={(e) => {
                  itemObj.Name = e.target.value;
                }}
              ></TextInput72Percent>

              {itemObj.CateType === CategoryTypes.Material ||
              itemObj.CateType === CategoryTypes.Open ||
              itemObj.CateType === CategoryTypes.Addon ? (
                <div>
                  <LeagueSpartanRegular>
                    Quantity ({itemObj.Unit}) of Product
                  </LeagueSpartanRegular>
                  <TextInput72Percent
                    key={itemObj.Quant}
                    defaultValue={itemObj.Quant}
                    onChange={(e) => {
                      itemObj.Quant = e.target.value;
                    }}
                  ></TextInput72Percent>
                </div>
              ) : null}

              {itemObj.CateType === CategoryTypes.Removal ? (
                <div>
                  <LeagueSpartanRegular>
                    Area (Square Feet)*
                  </LeagueSpartanRegular>
                  <TextInput72Percent
                    onChange={(e) => {
                      setRemovalArea(e.target.value);
                    }}
                  ></TextInput72Percent>
                  <LeagueSpartanRegular>Depth (Inches)*</LeagueSpartanRegular>
                  <TextInput72Percent
                    onChange={(e) => {
                      setRemovalDepth(e.target.value);
                    }}
                  ></TextInput72Percent>
                </div>
              ) : null}

              {itemObj.CateType === CategoryTypes.Labor ? (
                <div>
                  <LeagueSpartanRegular>Hours</LeagueSpartanRegular>
                  <TextInput72Percent
                    key={itemObj.Quant}
                    defaultValue={itemObj.Quant}
                    onChange={(e) => {
                      itemObj.Quant = e.target.value;
                    }}
                  ></TextInput72Percent>
                </div>
              ) : null}

              {itemObj.CateType === CategoryTypes.Open ? (
                <div>
                  <LeagueSpartanRegular>Unit</LeagueSpartanRegular>
                  <TextInput72Percent
                    key={itemObj.Unit}
                    defaultValue={itemObj.Unit}
                    onChange={(e) => {
                      itemObj.Unit = e.target.value;
                    }}
                  ></TextInput72Percent>
                  <LeagueSpartanRegular>Product Price</LeagueSpartanRegular>
                  <TextInput72Percent
                    key={itemObj.UnitPrice}
                    defaultValue={itemObj.UnitPrice}
                    onChange={(e) => {
                      itemObj.UnitPrice = e.target.value;
                    }}
                  ></TextInput72Percent>
                </div>
              ) : null}

              {itemObj.CateType === CategoryTypes.Material ||
              itemObj.CateType === CategoryTypes.Open ? (
                <div>
                  <LeagueSpartanRegular>Tag</LeagueSpartanRegular>
                  <TextSelect72Percent
                    defaultValue={itemObj.Tag_Id}
                    onChange={(e) => {
                      itemObj.Tag_Id = e.target.value;
                    }}
                  >
                    <DatalistOption value={0}>General Materials</DatalistOption>
                    {taglist.map((o, i) => (
                      <DatalistOption value={o.Id} key={i}>
                        {o.TagName}
                      </DatalistOption>
                    ))}
                  </TextSelect72Percent>
                </div>
              ) : null}

              <LeagueSpartanRegular>Notes</LeagueSpartanRegular>
              <TextArea72Percent
                key={itemObj.Notes}
                defaultValue={itemObj.Notes}
                onChange={(e) => {
                  itemObj.Notes = e.target.value;
                }}
              ></TextArea72Percent>

              <Cont100PercentWidth>
                <br></br>
                <RegularResponsiveButton
                  onClick={() => {
                    SaveItem(itemObj);
                  }}
                >
                  Update
                </RegularResponsiveButton>
              </Cont100PercentWidth>
              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  onClose(false);
                  ResetCalculator();
                }}
              >
                Cancel
              </RegularResponsiveButton>
            </Div300pxMargin30px>
          </StickyCont400x400px>
          {itemObj.CateType === CategoryTypes.Material ? (
            <div>
              <AbsoluteCalculatorCont>
                <Div300pxMargin30px>
                  <LeagueSpartanRegular>
                    Square Feet to Cubic Feet
                  </LeagueSpartanRegular>

                  <StyledHRGreyBreak></StyledHRGreyBreak>

                  <LeagueSpartanRegular>Square Feet</LeagueSpartanRegular>
                  <TextInput72Percent
                    onChange={(e) => {
                      setSquareFeet(e.target.value);
                    }}
                  ></TextInput72Percent>

                  <LeagueSpartanRegular>Depth (Inches)</LeagueSpartanRegular>
                  <TextInput72Percent
                    onChange={(e) => {
                      setDepth(e.target.value);
                    }}
                  ></TextInput72Percent>
                  <Cont100PercentWidth>
                    <LeagueSpartanRegular>
                      Cubic Feet: {cubicFeetTotal.toFixed(2)}
                    </LeagueSpartanRegular>
                  </Cont100PercentWidth>
                </Div300pxMargin30px>
              </AbsoluteCalculatorCont>
            </div>
          ) : null}
        </FixedOverlay1000vh>{" "}
      </>
    );
  } else {
    return (
      <div>
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
};

QuoteItemWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  RefreshItemList: () => {},
  RefreshUpdatedItem: () => {},
  defaultName: "",
  unitForProduct: "",
  GetQuoteInfoById:() => {},
  onCloseNoAdd:() => {}
};

export default QuoteItemWindow;
