import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AddQuote, GetCaseById, GetQuoteById, GetQuoteObject, UpdateQuote_AccessArea } from '../../API_Functions/CaseQuote_Functions';
import { AddImageToCloud, GetCurrentTime } from '../../API_Functions/Utility_Functions';
import { LeagueHeader14px } from '../../MobileComponents/Text';
import { ColorPalette } from '../../StylesheetComps/Colors';
import { RegularResponsiveButton } from '../../StylesheetComps/Components';
import { FlexContSpaceEvenly, FlexDiv } from '../../StylesheetComps/Cont';
import { FlexDivSpaceAround, FlexDivSpaceBetween } from '../../StylesheetComps/Div';
import { SmallHeader } from '../../StylesheetComps/Menu';
import { LeagueSpartanRegular, SmallTitleBold } from '../../StylesheetComps/Titles';
import { Image300x200, ImageFullWidth } from '../../StylesheetComps/LandscapeForm';
import { Image80x100px } from '../../StylesheetComps/Imgs/Images';
import QuotePhotoUploadPreviewWindow from '../../comps/Windows/QuotePhotoUpload';
import SiteMapOverlayUpload from '../../comps/Windows/SitemapOverlay';

// Styled Components
const PopupContainer = styled.div`
  width:100vw;
  height:100vh;
  display: ${({visible}) => visible ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: -10vh;
  left: 0;
  right: 0;
  bottom: 5;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const PopupContent = styled.div`
  width: 85%;
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
`;

const InfoText = styled.p`
  margin-bottom: 20px;
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 85%;
  padding: 10px;
  margin-right: 30px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const MapIcon = styled.span`
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url('/pinicon.svg') no-repeat center;
  background-size: contain;
  cursor: pointer;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
`;

// Popup Component
const AreaInputPopup = ({ visible, onClose, mapArea, maplength, mapwidth, FinishAccess, CloseWindow, CloseWindowNoAdd, shapes, UsePhotoAsOverlay }) => {
    const [infoAcknowledged, setInfoAcknowledged] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);
    const [elevation, setElevation] = useState(0);
    const [accessPopup, setAccessPopup] = useState(false); // new state variable for access popup
    const [showInputForm, setShowInputForm] = useState(true);
    const [obstruction, setObstruction] = useState('false');

    const [updateAccess, setUpdateAccess] = useState(false);

    const [uploadCustom, setUploadCustom] = useState(false);
    const inputFile = useRef(null);

    const handleUpload = (event) => {
      setPhoto(event.target.files[0]);
    };
    const history = useHistory();
    const params = useParams();

    let formdata = new FormData();
    const [photo, setPhoto] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [previewImageWindowDisplay, setPreviewImageWindowDisplay] =
    useState(false);
    const handleChange = (event) => {
        setObstruction(event.target.value);
    };

    const AddPhotoButtonClick = () => {
      inputFile.current.click();
    };  

    const OpenUploadImageWindow = async (photo) => {
      // We want to use the try-catch pattern here to handle any errors that
      // may occur when making the API call to AddImageToCloud
      try {
        // Check if we have a photo
        if (photo !== null) {
          // Add the photo to the form data
          formdata.append("Overlay Photo", photo);
  
          // Make the API call and store the result in a variable
          const uploadedImage = await AddImageToCloud(formdata);
  
          // If we have a result, set the preview image and open the window
          if (uploadedImage !== null) {
            setPreviewImage(uploadedImage);
            setUploadCustom(true)
          }
        }
      } catch (error) {
        // Handle any errors that occurred during the API call
        console.error(error);
      }
    };

    useEffect(() => {
      if (photo !== "") {
        OpenUploadImageWindow(photo);
      }
    }, [photo]); // eslint-disable-line react-hooks/exhaustive-deps

    const CreateNewEstimate = async (length, width, elevation, obstruction) => {
      try {
        const quoteObj = await GetQuoteObject()
        if(quoteObj !== null){
          quoteObj.AccessArea.Obstruct = obstruction;
          quoteObj.AccessArea.Width = width;
          quoteObj.AccessArea.Length = length;
          quoteObj.AccessArea.Elevation = elevation;
          var addtime = await GetCurrentTime();
          var proposal = await GetNewQuoteProposalNumber(params.caseId);
          quoteObj.Case_Id = params.caseId;
          quoteObj.AddTime = addtime;
          quoteObj.Proposal = proposal;
          quoteObj.AccessArea.Case_Id = params.caseId;
          quoteObj.Tittle = 'New Estimate:'
          const data = await AddQuote(quoteObj)
          if(data !== null){
            history.push(
              `/viewestimatestaff/${params.Id}/${params.storeId}/${params.caseId}/${data.quote.Id}/0`
            );
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    const GetNewQuoteProposalNumber = async (caseid) => {
      var data = await GetCaseById(caseid);
      if (data != null) {
        if (data.Case?.Quotes?.length > 0) {
          var proposal = 0;
          data.Case.Quotes.forEach((element) => {
            if (element.Proposal > proposal) proposal = element.Proposal;
          });
          proposal++;
          return proposal;
        } else return 0;
      } else return 0;
    };
  

    const UpdateAccessFunction = async (length, width, elevation, obstruction) => {
      const quotedata = await GetQuoteById(params.quoteId)
      if(quotedata){
          var item = {
        Id: quotedata?.quote?.AccessArea?.Id,
        Width: width,
        Length: length,
        Elevation: elevation,
        Obstruct: obstruction,
      };
      const data = await UpdateQuote_AccessArea(item)
      if(data){
      CloseWindowNoAdd();
      }
      }
    
    }
  
    const onAccessPopupOK = () => {
        setAccessPopup(false); // close the access popup
      onClose(); // go back to the map
    };

  useEffect(() => {
  if(maplength){
    setLength(maplength?.toFixed(2))
  }
  }, [maplength]);

  useEffect(() => {
    if(mapwidth){
      setWidth(mapwidth?.toFixed(2))
    }
  }, [mapwidth]);

  // useEffect(() => {
  //   if(params.quoteId !== '0'){
  //     setInfoAcknowledged(true)
  //     setUpdateAccess(true)
  //     setShowInputForm(true)
  //   }
  // }, [visible]);



//   <>
//   <LeagueHeader14px>New Rhodes Construction SiteMapper™ </LeagueHeader14px>
//   <InfoText>
//     Start by providing access details (length, width, elevation) using drawing or manual entry.
//     <br></br>
//     <br></br>
//     Add items to estimate using drawing tools.
//     <br></br>
//     <br></br>
//     Replace removed items if needed. Use text and utilities tool. 
//     <br></br>
//     <br></br>
//     Review and submit. Enjoy!
//   </InfoText>
//   <RegularResponsiveButton width='15px' onClick={() => setInfoAcknowledged(true)}>
//     OK
//   </RegularResponsiveButton>
// </>

// return (
//     <PopupContainer visible={visible}>
//       <PopupContent>
//         {!infoAcknowledged ? (
//           <>
//             <LeagueHeader14px>New Rhodes Construction SiteMapper™ </LeagueHeader14px>
//             <InfoText>
//               Start by choosing to use the map, or uploading a custom overlay for properties with outdated satellite imagery.
//               <br></br>
//             </InfoText>
//             <FlexDivSpaceBetween>
//             <Image300x200 width='200px' height='100px' margin='0px' src='/nrcmap.png'></Image300x200>
//             <Image300x200 width='200px' height='100px' margin='0px' src='/siteplanoverlay.jpg'></Image300x200>
//             </FlexDivSpaceBetween>
//             <br></br>
//             <FlexContSpaceEvenly>
//             <RegularResponsiveButton marginright='50px' onClick={() => setInfoAcknowledged(true)}>Google Map</RegularResponsiveButton>  
//             <RegularResponsiveButton onClick={()=>{setUploadCustom(true)}}>Custom Overlay</RegularResponsiveButton>  
//             </FlexContSpaceEvenly>
//             {/* <RegularResponsiveButton width='15px' onClick={() => setInfoAcknowledged(true)}>
//               OK
//             </RegularResponsiveButton> */}
//           </>
//         ) : uploadCustom === true ? (

//         ) : (
//           <>
//             {showInputForm && (
//               <form>
//                 <LeagueSpartanRegular>{params.quoteId === undefined ? 'Create Estimate, Define Access:' : updateAccess ? 'Update Access Area:' : 'Access Area:'}</LeagueSpartanRegular>
//                 <InputLabel>Length</InputLabel>
//                 <InputGroup>
//                   <Input value={length} onChange={e => setLength(e.target.value)} required />
//                   {params.quoteId === undefined
//                   ? null :
//                   <MapIcon onClick={() => {
//                     onClose('length'); // close the popup
//                   }} />
//                 }
//                 </InputGroup>
//                 <InputLabel>Width</InputLabel>
//                 <InputGroup>
//                   <Input value={width} onChange={e => setWidth(e.target.value)} required />
//                   {params.quoteId === undefined
//                   ? null :
//                   <MapIcon onClick={() => {
//                     onClose('width'); // close the popup
//                   }} />
//                   }
                 
//                 </InputGroup>
//                 <InputLabel>Elevation</InputLabel>
//                 <InputGroup>
//                   <Input value={elevation} onChange={e => setElevation(e.target.value)} required />
//                   {/* <MapIcon onClick={() => {
//                     mapArea('elevation');
//                     onClose(); // close the popup
//                   }} /> */}
//                 </InputGroup>
//                 <LeagueSpartanRegular>Any Obstructions to the Access Area?</LeagueSpartanRegular>
//                             <FlexDivSpaceAround>
//                             <label>
//                                 <input
//                                 type="radio"
//                                 value="true"
//                                 checked={obstruction === 'true'}
//                                 onChange={handleChange}
//                                 />
//                                 YES 
//                             </label>
//                             <label>
//                                 <input
//                                 type="radio"
//                                 value="false"
//                                 checked={obstruction === 'false'}
//                                 onChange={handleChange}
//                                 />
//                                 NO 
//                             </label>
//                             </FlexDivSpaceAround>
//                 <br></br>
//                 <SmallTitleBold color={ColorPalette.PrimaryRed}>{errorText}</SmallTitleBold>
//                 <FlexDiv>
//                 <RegularResponsiveButton width="75%" type="submit" onClick={() => {
//                 if(length == 0 || width == 0){
//                   setErrorText("Please ensure Length and Width are above 0!")
//                 } else if (shapes?.length < 2) {
//                   setErrorText("Please draw both access length and width on the map!")
//                 }
//                 else if (params.quoteId === undefined) {
//                    CreateNewEstimate(length, width, elevation, obstruction)
//                 }
//                 else if (updateAccess) {
//                   UpdateAccessFunction(length, width, elevation, obstruction);
//                   // setShowInputForm(false); // hides the input form
//                   // setAccessPopup(false); // shows the access instructions popup
//                 } else {
//                   FinishAccess(length, width, elevation, obstruction);
//                   setShowInputForm(false); // hides the input form
//                 setAccessPopup(true); // shows the access instructions popup
//                 }
                
//               }}>
//                 {params.quoteId === undefined ? 'Create Estimate' : updateAccess ? 'Update Changes:' : 'Save'}
//               </RegularResponsiveButton>
//               {params.quoteId === undefined || updateAccess ? 
//               <RegularResponsiveButton onClick={()=>{onClose()}} bgcolor={ColorPalette.PrimaryRed}>Cancel</RegularResponsiveButton>
//               : null }
//               </FlexDiv>
              
//               </form>
//             )}
//             {accessPopup && (
//               <PopupContent>
//                  <LeagueHeader14px>Add Access Items </LeagueHeader14px>
//             <InfoText>
//               Great! After entering the length, width, and elevation, you can begin estimating on the map.
//               <br></br>
//               <br></br>
//               Start with your access items, then removals, material installation, etc.
//               <br></br>
//               <br></br>
//               Once you're done estimating, hit 'Save Estimate' and you can proceed to your estimate! 
//             </InfoText>
//                 <RegularResponsiveButton onClick={() => {
//                   CloseWindow(); // close the popup
//                   setAccessPopup(false); // hides the access instructions popup
//                 }}>
//                   OK
//                 </RegularResponsiveButton>
//               </PopupContent>
//             )}
//           </>
//         )}
//       </PopupContent>
//     </PopupContainer>
//   );  
// };

return (
  <PopupContainer visible={visible}>
    <PopupContent>
      {!infoAcknowledged && !uploadCustom ? (
        <>
          <LeagueHeader14px>New Rhodes Construction SiteMapper™</LeagueHeader14px>
          <InfoText>
            Start by choosing to use the map, or uploading a custom overlay for properties with outdated satellite imagery.
            <br></br>
          </InfoText>
          <FlexDivSpaceBetween>
            <Image300x200 width="200px" height="100px" margin="0px" src="/nrcmap.png"></Image300x200>
            <Image300x200 width="200px" height="100px" margin="0px" src="/siteplanoverlay.jpg"></Image300x200>
          </FlexDivSpaceBetween>
          <br></br>
          <FlexContSpaceEvenly>
            <RegularResponsiveButton marginright="50px" onClick={() => setInfoAcknowledged(true)}>Google Map</RegularResponsiveButton>
            <RegularResponsiveButton onClick={() => {                        AddPhotoButtonClick();}}>Custom Overlay</RegularResponsiveButton>
          </FlexContSpaceEvenly>
          <input
        style={{ display: "none" }}
        ref={inputFile}
        type="file"
        onChange={handleUpload}
      />
          {/* <RegularResponsiveButton width="15px" onClick={() => setInfoAcknowledged(true)}>
            OK
          </RegularResponsiveButton> */}
        </>
      ) : uploadCustom ? (
        <div>
      <SiteMapOverlayUpload
      UsePhotoAsOverlay={(previewsrc)=>{UsePhotoAsOverlay(previewsrc);
        setUploadCustom(false)
        setInfoAcknowledged(true)
      }}
      open={true}
      CancelUpload={()=>{
        setPhoto('')
        setUploadCustom(false)
      }}
      previewsrc={previewImage}
      CloseWindow={() => {
        setUploadCustom(false)
      }}
      UploadSuccessful={() => {

      }}
    ></SiteMapOverlayUpload>
    </div>
      ) : (
        <>
          {showInputForm && (
            <form>
              <LeagueSpartanRegular>{params.quoteId === undefined ? 'Create Estimate, Define Access:' : updateAccess ? 'Update Access Area:' : 'Access Area:'}</LeagueSpartanRegular>
              <InputLabel>Length</InputLabel>
              <InputGroup>
                <Input value={length} onChange={e => setLength(e.target.value)} required />
                {params.quoteId !== undefined && (
                  <MapIcon onClick={() => {
                    onClose('length'); // close the popup
                  }} />
                )}
              </InputGroup>
              <InputLabel>Width</InputLabel>
              <InputGroup>
                <Input value={width} onChange={e => setWidth(e.target.value)} required />
                {params.quoteId !== undefined && (
                  <MapIcon onClick={() => {
                    onClose('width'); // close the popup
                  }} />
                )}
              </InputGroup>
              <InputLabel>Elevation</InputLabel>
              <InputGroup>
                <Input value={elevation} onChange={e => setElevation(e.target.value)} required />
                {/* <MapIcon onClick={() => {
                  mapArea('elevation');
                  onClose(); // close the popup
                }} /> */}
              </InputGroup>
              <LeagueSpartanRegular>Any Obstructions to the Access Area?</LeagueSpartanRegular>
              <FlexDivSpaceAround>
                <label>
                  <input
                    type="radio"
                    value="true"
                    checked={obstruction === 'true'}
                    onChange={handleChange}
                  />
                  YES
                </label>
                <label>
                  <input
                    type="radio"
                    value="false"
                    checked={obstruction === 'false'}
                    onChange={handleChange}
                  />
                  NO
                </label>
              </FlexDivSpaceAround>
              <br></br>
              <SmallTitleBold color={ColorPalette.PrimaryRed}>{errorText}</SmallTitleBold>
              <FlexDiv>
                <RegularResponsiveButton width="75%" type="submit" onClick={() => {
                  if (length == 0 || width == 0) {
                    setErrorText("Please ensure Length and Width are above 0!");
                  } else if (shapes?.length < 2) {
                    setErrorText("Please draw both access length and width on the map!");
                  } else if (params.quoteId === undefined) {
                    CreateNewEstimate(length, width, elevation, obstruction);
                  } else if (updateAccess) {
                    UpdateAccessFunction(length, width, elevation, obstruction);
                    // setShowInputForm(false); // hides the input form
                    // setAccessPopup(false); // shows the access instructions popup
                  } else {
                    FinishAccess(length, width, elevation, obstruction);
                    setShowInputForm(false); // hides the input form
                    setAccessPopup(true); // shows the access instructions popup
                  }
                }}>
                  {params.quoteId === undefined ? 'Create Estimate' : updateAccess ? 'Update Changes:' : 'Save'}
                </RegularResponsiveButton>
                {(params.quoteId === undefined || updateAccess) && (
                  <RegularResponsiveButton onClick={() => { onClose() }} bgcolor={ColorPalette.PrimaryRed}>Cancel</RegularResponsiveButton>
                )}
              </FlexDiv>
            </form>
          )}
          {accessPopup && (
            <PopupContent>
              <LeagueHeader14px>Add Access Items</LeagueHeader14px>
              <InfoText>
                Great! After entering the length, width, and elevation, you can begin estimating on the map.
                <br></br>
                <br></br>
                Start with your access items, then removals, material installation, etc.
                <br></br>
                <br></br>
                Once you're done estimating, hit 'Save Estimate' and you can proceed to your estimate!
              </InfoText>
              <RegularResponsiveButton onClick={() => {
                CloseWindow(); // close the popup
                setAccessPopup(false); // hides the access instructions popup
              }}>
                OK
              </RegularResponsiveButton>
            </PopupContent>
          )}
        </>
      )}
    </PopupContent>
  </PopupContainer>
)};

AreaInputPopup.defaultProps = {
    visible:'flex',
    mapArea:()=>{},
    onClose:()=>{},
    FinishAccess:()=>{},
    CloseWindow:()=>{},
    CloseWindowNoAdd:()=>{},
    UsePhotoAsOverlay:()=>{},
}

export default AreaInputPopup;
