import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DivWithDisplay,
  DivWithMarginPadding,
  FlexDiv,
  FlexRowContFullWidth
} from "../../StylesheetComps/Cont";
import { Icon20x20, Icon30x30 } from "../../StylesheetComps/Imgs/Icons";
import { RegularText, SmallTitleBold } from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FlexDivSpaceBetween } from "../../StylesheetComps/Div";
import { ColorPalette } from "../../StylesheetComps/Colors";

const CategoryCard = ({ CatHeadText, EditCat, DelCat, catId, onView, selectedCategory }) => {
  const history = useHistory();
  const [roleNum, setRoleNum] = useState(UserRole.Loading);
  const [storeId, setStoreId] = useState(0);
  const [bgColor, setBgColor] = useState('');

  const GetSessionInfo = async () => {
    try {
      const userInfoString = sessionStorage.getItem("userInfo");
      
      // Check if userInfoString is not null or undefined
      if (!userInfoString) {
        console.error("No user info found in session storage.");
        return; // Early return to stop the function execution
      }
  
      const userInfo = JSON.parse(userInfoString);
  
      // Additional checks to ensure the data structure includes the expected properties
      if (userInfo.Role && userInfo.Store_Id) {
        setRoleNum(userInfo.Role);
        setStoreId(userInfo.Store_Id);
      } else {
        console.error("Invalid user info structure.", userInfo);
      }
    } catch (error) {
      console.error("Error parsing user info from session storage:", error);
    }
  };  

  const [selectedIndex, setSelectedIndex] = useState(null);
  
  useEffect(() => {
    GetSessionInfo();
  }, []);

  if (roleNum === UserRole.Admin || roleNum === UserRole.Manager) {
    return (
      <FlexRowContFullWidth onClick={()=>{
        if(selectedCategory === catId){
          selectedCategory(0)
          setSelectedIndex(0)
        } else {
          selectedCategory(catId)
          setSelectedIndex(catId)
        }
      }} 
      // bgcolor={selectedIndex === catId ? ColorPalette.LightGrey : 'white'}
      hovermargin='5px' hoverbgcolor={ColorPalette.LightGrey}>
      <FlexDivSpaceBetween width='90%'>
      <FlexDiv width='30vw'>
        <Icon20x20 src='/Inventory.svg' />
        <RegularText onClick={() => {
              history.push(`/productlist/${storeId}/${catId}/0`);
              onView();
            }}>
        {CatHeadText}
        </RegularText>
        </FlexDiv>
        <FlexDiv>
        <Icon30x30 src={"/EditIcon.svg"} onClick={EditCat}></Icon30x30>
        <Icon30x30 src={"/Garbage_Icon.svg"}
        //  onClick={DelCat}
         ></Icon30x30>
        </FlexDiv>
        </FlexDivSpaceBetween>
        <hr></hr>
      </FlexRowContFullWidth>
      // <DivWithMarginPadding height="85px" width="120px">
      //   <DivWithDisplay width="120px">
      //     <SmallTitleBold
      //       onClick={() => {
      //         history.push(`/productlist/${storeId}/${catId}`);
      //         onView();
      //       }}
      //     >
      //       {CatHeadText}
      //     </SmallTitleBold> 
      //     <DivWithDisplay>
      //       <DivWithDisplay flexdirection="row" width="120px">
      //         <DivWithDisplay>
      //           <Icon30x30 src={"/EditIcon.svg"} onClick={EditCat}></Icon30x30>
      //         </DivWithDisplay>
      //         <DivWithDisplay>
      //         <Icon30x30 src={"/Garbage_Icon.svg"} onClick={DelCat}></Icon30x30>
      //         </DivWithDisplay>
      //       </DivWithDisplay>
      //     </DivWithDisplay>
      //   </DivWithDisplay>
      //   <DivWithDisplay></DivWithDisplay>
      // </DivWithMarginPadding>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

CategoryCard.defaultProps = {
  onView: () => {},
  selectedCategory: () => {},
  CatHeadText: "Fencing",
  catId: 0,
};

export default CategoryCard;
