import styled from "styled-components";
import { ColorPalette } from "../../StylesheetComps/Colors";

const nullProp = null;

export const MobileMenuLinkText = styled.p`
  font-family: Futura;
  font-size: 18px;
  margin-right: auto;
  width: 50%;
  margin-left: 3vw;
`;

export const WhiteAvenirHeader = styled.p`
  font-family: Avenir;
  color: white;
  font-size: 16px;
  margin: 0;
  margin-left: 25px;
`;

export const WhiteLeagueHeader = styled.p`
  font-family: LeagueSpartan-Bold;
  color: white;
  font-size: 20px;
  margin: 0;
  margin-left: 25px;
`;

export const GreenLeagueText = styled.p`
  font-family: LeagueSpartan-Bold;
  color: ${ColorPalette.PrimaryButtonBlue};
  font-size: 24px;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 0px;
`;

export const RegularGreenLeagueText = styled.p`
  font-family: LeagueSpartan-Bold;
  color: ${ColorPalette.PrimaryButtonBlue};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "18px")};
  margin-top: 10px;
  margin-bottom: 0px;
  cursor: pointer;
`;

export const MobileBodyText = styled.p`
  font-family: Avenir;
  font-size: 16px;
  width: ${(props) => (props.width ? props.width : "70%")};
  margin: 10px;
`;

export const MobileHeader = styled.p`
  font-family: Avenir;
  font-size: 16px;
  width: auto;
  margin: 14px 0px 10px 12px;
`;

export const MobileBodyTextFullWidth = styled.p`
  font-family: Avenir;
  font-size: 16px;
  width: 95%;
  margin: 10px;
`;

export const MenuHeader = styled.p`
  font-family: LeagueSpartan-Bold;
  font-size: 16px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const LeagueHeader14px = styled.p`
  font-family: LeagueSpartan-Bold;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const LeagueHeader12px = styled.p`
  font-family: LeagueSpartan-Bold;
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const UnderlinedBoldTitle16px = styled.p`
  font-family: Avenir;
  font-size: 16px;
  font-weight: Bold;
  padding-bottom: value;
  border-bottom-style: solid;
`;
export const ErrorMessage12px = styled.p`
  font-size: 12px;
  color: red;
`;

export default nullProp;
