import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ColorPalette } from "../../StylesheetComps/Colors";
import moment from "moment";
import {
  AddQuoteRevision,
  BidStatus,
  GetCaseById,
  GetQuoteById,
  QuoteStatus,
  SetQuoteTo_Buy_Install,
  SetQuoteTo_Created,
  SetQuoteTo_Expire,
  SetQuoteTo_Notify,
} from "../../API_Functions/CaseQuote_Functions";
import { FlexColumnCont } from "../../StylesheetComps/Menu";
import { MediumHeader100PercentWidth } from "../../StylesheetComps/Titles";
import { DivWithDisplay, FlexColumnPropWidth } from "../../StylesheetComps/Cont";
import { LargeResponsiveButton } from "../../StylesheetComps/AuthSheet";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { CaseStatus } from "../../StylesheetComps/CaseStatus";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import Dropdown from "../DropdownMenu";
import { GetCurrentTime } from "../../API_Functions/Utility_Functions";
import styled from "styled-components";

const CaseCardContainer = styled(DivWithDisplay)`
  padding: 5px;
  background-color: ${({ isOdd }) => (isOdd ? '#FCFDFB' : '#ffffff')};
  display: flex;
  justify-content: space-evenly;
  transition: padding 0.2s ease, background-color 0.2s ease;

  &:hover {
    padding: 10px; 
    background-color: #F8F6F6;
  }
`;

const ProposalCardGuide = ({
  proposalObj,
  quoteid,
  proposallist,
  UpdateProjectUI,
  DeleteProposal,
}) => {
  const [continueQuoteDisplay, setContinueQuoteDisplay] = useState("none");
  const [notifyHomeownerDisplay, setNotifyHomeownerDisplay] = useState("none");
  const [editQuoteDisplay, setEditQuoteDisplay] = useState("block");
  const [highestRevisionNumber] = useState(0);
  const [savingRevision, setSavingRevision] = useState(false);
  const [estimateTextWidth, setEstimateTextWidth] = useState("100%");

  const revisionMarginLeft = "0px";
  const proposalBorder = "1px solid " + ColorPalette.PrimaryGrey;
  const revisionBorder = "1px solid " + ColorPalette.TertiaryGrey;

  const [statusText, setStatusText] = useState("");
  const [revisionText, setRevisionText] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [marginLeftRevision, setMarginLeftRevision] = useState("");
  const [revisionBorderProp, setRevisionBorderProp] = useState(proposalBorder);

  const history = useHistory();
  var quotetag = "";

  // const UpdateUI_BasedOnQuoteStatus = async (status, revision) => {
  //   if (status !== null && revision !== null) {
  //     switch (status) {
  //       case QuoteStatus.Created:
  //         setStatusText("Created");
  //         setStatusColor(ColorPalette.PrimaryButtonBlue);
  //         setNotifyHomeownerDisplay("block");
  //         setEditQuoteDisplay("block");
  //         break;
  //       case QuoteStatus.UserNotified:
  //         setStatusText("Sent");
  //         setStatusColor(ColorPalette.PrimaryButtonBlue);
  //         setNotifyHomeownerDisplay("block");
  //         setEditQuoteDisplay("block");
  //         break;
  //       case QuoteStatus.RevisionRequested:
  //         setStatusText("Revision Pending");
  //         setStatusColor(ColorPalette.PrimaryGrey);
  //         break;
  //       case QuoteStatus.Approved:
  //         setStatusText("Approved");
  //         setStatusColor(ColorPalette.PrimaryButtonBlue);
  //         setEditQuoteDisplay("block");
  //         break;
  //       case QuoteStatus.Declined:
  //         setStatusText("Declined");
  //         setStatusColor(ColorPalette.PrimaryRed);
  //         setEditQuoteDisplay("block");
  //         break;
  //       case QuoteStatus.Buy:
  //         setStatusText("Supply Material");
  //         setStatusColor(ColorPalette.PrimaryButtonBlue);
  //         break;
  //       case QuoteStatus.Buy_Install:
  //         setStatusText("Accepted");
  //         setStatusColor(ColorPalette.PrimaryButtonBlue);
  //         break;
  //       case QuoteStatus.Expired:
  //         setStatusText("Expired");
  //         setStatusColor(ColorPalette.PrimaryRed);
  //         break;
  //       case QuoteStatus.Canceled:
  //         setStatusText("Cancelled");
  //         setStatusColor(ColorPalette.PrimaryRed);
  //         break;
  //       default:
  //     }
  //     if (proposalObj.Tittle !== null) {
  //       quotetag = " - " + proposalObj.Tittle;
  //     } else {
  //       quotetag = " ";
  //     }
  //     if (proposalObj.Revision === 0) {
  //       switch (proposalObj.Proposal) {
  //         case 0:
  //           setRevisionText("Proposal" + quotetag);
  //           break;
  //         default:
  //           setRevisionText("Option " + proposalObj.Proposal + quotetag);
  //       }
  //     } else {
  //       switch (proposalObj.Proposal) {
  //         case 0:
  //           setEstimateTextWidth("85%");
  //           setMarginLeftRevision(revisionMarginLeft);
  //           setRevisionBorderProp(revisionBorder);
  //           setRevisionText(
  //             "Proposal - Revision " + proposalObj.Revision + quotetag
  //           );
  //           break;
  //         default:
  //           setEstimateTextWidth("85%");
  //           setRevisionBorderProp(revisionBorder);
  //           setMarginLeftRevision(revisionMarginLeft);
  //           setRevisionText(
  //             "Option " +
  //               proposalObj.Proposal +
  //               " Revision " +
  //               proposalObj.Revision +
  //               quotetag
  //           );
  //       }
  //     }
  //   }
  // };

  const UpdateUI_BasedOnQuoteStatus = async (status, revision) => {
    if (status !== null && revision !== null) {
      switch (status) {
        case QuoteStatus.Created:
          setStatusText("Created");
          setStatusColor(ColorPalette.PrimaryButtonBlue);
          setNotifyHomeownerDisplay("block");
          setEditQuoteDisplay("block");
          break;
        case QuoteStatus.UserNotified:
          setStatusText("Sent");
          setStatusColor(ColorPalette.PrimaryButtonBlue);
          setNotifyHomeownerDisplay("block");
          setEditQuoteDisplay("block");
          break;
        case QuoteStatus.RevisionRequested:
          setStatusText("Revision Pending");
          setStatusColor(ColorPalette.PrimaryGrey);
          break;
        case QuoteStatus.Approved:
          setStatusText("Approved");
          setStatusColor(ColorPalette.PrimaryButtonBlue);
          setEditQuoteDisplay("block");
          break;
        case QuoteStatus.Declined:
          setStatusText("Declined");
          setStatusColor(ColorPalette.PrimaryRed);
          setEditQuoteDisplay("block");
          break;
        case QuoteStatus.Buy:
          setStatusText("Supply Material");
          setStatusColor(ColorPalette.PrimaryButtonBlue);
          break;
        case QuoteStatus.Buy_Install:
          setStatusText("Accepted");
          setStatusColor(ColorPalette.PrimaryButtonBlue);
          break;
        case QuoteStatus.Expired:
          setStatusText("Expired");
          setStatusColor(ColorPalette.PrimaryRed);
          break;
        case QuoteStatus.Canceled:
          setStatusText("Cancelled");
          setStatusColor(ColorPalette.PrimaryRed);
          break;
        default:
      }
  
      if (proposalObj.Tittle !== null) {
        quotetag = proposalObj.Tittle;
      } else {
        quotetag = "";
      }
  
      setEstimateTextWidth("85%");
      setMarginLeftRevision(revisionMarginLeft);
      setRevisionBorderProp(revisionBorder);
  
      if (quotetag !== "") {
        setRevisionText(quotetag);
      }
    }
  };
  

  const UpdateUI_BasedOnCaseStatus = async (status) => {
    if (status !== null) {
      switch (proposalObj.CaseStatus) {
        case CaseStatus.Accepted:
          setContinueQuoteDisplay("block");
          break;

        default:
          setContinueQuoteDisplay("none");
          break;
      }
    }
  };

  const DuplicateProposal = async (proposalObj) => {
    const quoteData = await GetQuoteById(proposalObj.Id)
    if(quoteData !== null){
      var newProposalObject = quoteData.quote
      var data = await GetCaseById(newProposalObject.Case_Id);
      if (data != null) {
        var quotes = data.Case.Quotes;
        var proposal = 0;
        quotes.forEach((q) => {
          if (q.Proposal > proposal) {
            proposal = q.Proposal;
          }
        });
        newProposalObject.Proposal = proposal + 1;
        newProposalObject.Revision = 0;
        newProposalObject.AddTime = await GetCurrentTime();
  
        setSavingRevision(true);
        await AddProposal(newProposalObject);
      }
    }
  
  }

  const AddProposal = async (proposalObj) => {
    try {
      await AddQuoteRevision(proposalObj);
    } catch (error) {
      console.error('Error while adding quote revision:', error);
    } finally {
      setSavingRevision(false);
      UpdateProjectUI()
    }
  };

  const MarkAsAccepted = async (quoteid) => {
    const data = await SetQuoteTo_Buy_Install(quoteid)
    if(data !== null) {
      UpdateProjectUI()
      setSavingRevision(false)
    }
  }

  const ArchiveEstimate = async (quoteid) => {
    const data = await SetQuoteTo_Expire(quoteid)
    if(data !== null){
      UpdateProjectUI()
      setSavingRevision(false)
    }
  }

  const MarkAsCreated = async (quoteid) => {
    const data = await SetQuoteTo_Created(quoteid)
    if(data !== null){
      UpdateProjectUI()
      setSavingRevision(false)
    }
  }

  useEffect(() => {
    UpdateUI_BasedOnQuoteStatus(proposalObj.QuoteStatus, proposalObj.Revision);
    if(proposalObj.BidFlag === BidStatus.Bidding)
      setStatusText("Under bidding");
  }, [proposalObj.QuoteStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    UpdateUI_BasedOnCaseStatus(proposalObj.CaseStatus);
  }, [proposalObj.CaseStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(proposalObj.BidFlag === BidStatus.Bidding)
      setStatusText("Under bidding");
  } ); // eslint-disable-line react-hooks/exhaustive-deps

  const NotifyHomeowner = async (quoteid) => {
    if (quoteid !== 0) {
      var data = await SetQuoteTo_Notify(quoteid);
      if (data !== null) {
        setNotifyHomeownerDisplay("none");
        alert("Notification Sent");
      }
    }
  };

  const GotoProposalDisplayPage = async (quotestatus, revisionnumber) => {
    switch (quotestatus) {
      case QuoteStatus.RevisionRequested:
        history.push(
          "/viewestimatestaff/" +
            params.Id +
            "/" +
            params.storeId +
            "/" +
            params.caseId +
            "/" +
            proposalObj.Id +
            "/0" 
          
        );
        break;

      default:
        // history.push('/viewquote/'+params.Id+"/"+params.storeId+"/"+params.caseId+"/"+proposalObj.Id+"/"+numberOfQuotes)
        history.push(
          "/viewestimatestaff/" +
            params.Id +
            "/" +
            params.storeId +
            "/" +
            params.caseId +
            "/" +
            proposalObj.Id +
            "/0" 
           
            
        );
        break;
    }
  };

  var numberOfQuotes = 0;
  const params = useParams();
  if (savingRevision === true) {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <CaseCardContainer>
      <FlexColumnCont
        marginleft={marginLeftRevision}
        mobilewidth="90vw"
        justifycontent="space-between"
        margin="0"
        mobilepadding='2%'
        margintop="0%"
        borderbottom={revisionBorderProp}
        height="100%"
        width="100%"
        flexdirection="row"
      >
        {/* <Icon30px30pxPointer
          // display={
          //   proposalObj?.QuoteStatus === QuoteStatus.Buy ||
          //   proposalObj?.QuoteStatus === QuoteStatus.Buy_Install
          //     ? "block"
          //     : "none"
          // }
          onClick={()=>{if(proposalObj?.QuoteStatus === QuoteStatus.Buy ||
            proposalObj?.QuoteStatus === QuoteStatus.Buy_Install){
              MarkAsCreated(quoteid)
            } else {
              MarkAsAccepted(quoteid)
            }
            setSavingRevision(true)
          }}
          src={proposalObj?.QuoteStatus === QuoteStatus.Buy ||
          proposalObj?.QuoteStatus === QuoteStatus.Buy_Install
            ? "/Green_Checkmark_Icon.svg"
            : "/White.png"}
        ></Icon30px30pxPointer> */}
    {
  proposalObj.lockedEstimate === true ? (
    <RegularResponsiveButton bgcolor={ColorPalette.PrimaryRed}>Locked-In</RegularResponsiveButton>
  ) : null
}

        <FlexColumnPropWidth mobiledisplay="flex" width="500px" mobilewidth="145px">
          <MediumHeader100PercentWidth
            cursor="pointer"
            onClick={() => {
              GotoProposalDisplayPage(
                proposalObj.QuoteStatus,
                highestRevisionNumber
              );
            }}
            width={estimateTextWidth}
          >
            {revisionText}
          </MediumHeader100PercentWidth>
        </FlexColumnPropWidth>

        <FlexColumnPropWidth mobiledisplay="flex" width="200px" mobilewidth='120px'>
          <MediumHeader100PercentWidth
            cursor="pointer"
            onClick={() => {
              GotoProposalDisplayPage(
                proposalObj.QuoteStatus,
                highestRevisionNumber
              );
            }}
            width={estimateTextWidth}
          >
            {moment(proposalObj.AddTime).format('ll')}
          </MediumHeader100PercentWidth>
        </FlexColumnPropWidth>

        <FlexColumnPropWidth
          justifycontent="flex-start"
          flexdirection="row"
          width="20%"
          mobiledisplay='none'
        >
          <LargeResponsiveButton
            bgcolor={statusColor}
            onClick={() => {
              GotoProposalDisplayPage(
                proposalObj.QuoteStatus,
                highestRevisionNumber
              );
            }}
          >
            {statusText}
          </LargeResponsiveButton>
        </FlexColumnPropWidth>

        <FlexColumnPropWidth
          mobiledisplay="flex"
          justifycontent="flex-start"
          flexdirection="row"
          width="5%"
        >
        <Dropdown currentPage={'proposalCardGuide'} quoteId={proposalObj.Id}
        deleteProposal={()=>{DeleteProposal()}} notifyProposal={()=>{NotifyHomeowner(quoteid);}} archiveProposal={()=>{setSavingRevision(true); ArchiveEstimate(quoteid)}} unArchiveProposal={()=>{setSavingRevision(true); MarkAsCreated(quoteid)}} acceptProposal={()=>{MarkAsAccepted(quoteid)}} resetProposal={()=>{
          MarkAsCreated(quoteid)
        }} duplicateProposal={()=>{DuplicateProposal(proposalObj)}}
        ></Dropdown>
        </FlexColumnPropWidth>
      </FlexColumnCont>
      </CaseCardContainer>
    );
  }
};

//Color Coordination -

ProposalCardGuide.defaultProps = {
  UpdateProjectUI: () => {},
  DeleteProposal: () => {},
  proposallist: [],
};

export default ProposalCardGuide;
