/* eslint-disable */

import { useEffect, useState } from "react";
import {
  BackgroundColorDiv,
  FixedItemPropertyDiv,
} from "../../MobileComponents/Divs";
import { TextInputProp } from "../../StylesheetComps/AuthSheet";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FlexColumnFullWidth,
  FlexRowCont100PercentSpaceBetween,
} from "../../StylesheetComps/Cont";
import {
  LeagueSpartanTiny,
  SmallGreenLeagueText,
  StyledHRPropColour,
  TinyHeaderDarkGrey,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";
const MobileQuoteItem = ({
  header1,
  header2,
  subheader2notes,
  subheader2,
  header3,
  header3title,
  header4,
  header4title,
  header5,
  header5title,
  header6,
  header6title,
  header7,
  header7title,
  header8,
  quoteItemObj,
  inputheaderposition,
  noChange,
  OutputValue,
  RemoveItemFromLocalQuoteObject,
  parentid,
  itemLink,
  bgColor,
}) => {
  const [itemPropertyDisplay, setItemPropertyDisplay] = useState("none");
  const [itemHRDisplay, setItemHRDisplay] = useState("none");
  const [itemPropertyToggle, setItemProperyToggle] = useState(false);

  const [subheader2Display] = useState("flex");
  const [header3Display, setHeader3Display] = useState("block");

  const [header3InputDisplay, setHeader3InputDisplay] = useState("none");

  const [descriptionOverflow, setDescriptionOverflow] = useState("hidden");
  const [descriptionWhiteSpace, setDescriptionWhiteSpace] = useState("nowrap");

  const UpdateDescriptionUI = async (overflow) => {
    if (overflow === "hidden") {
      setDescriptionOverflow("visible");
      setDescriptionWhiteSpace("normal");
    } else {
      setDescriptionOverflow("hidden");
      setDescriptionWhiteSpace("nowrap");
    }
  };

  const DetermineInputHeaderLogic = async () => {
    if (parentid === 0) {
      if (noChange === false) {
        InitializeSetup();
        switch (inputheaderposition) {
          case 1:
            break;
          case 2:
            break;
          case 3:
            setHeader3Display("none");
            UpdateItemPropertyView(false);
            setHeader3InputDisplay("flex");
            break;
          case 5:
            break;
          case 6:
            break;
          default:
            break;
        }
      }
    }
  };

  const InitializeSetup = async () => {
    setHeader3Display("block");

    setHeader3InputDisplay("none");
  };

  useEffect(() => {
    DetermineInputHeaderLogic(inputheaderposition);
  }, [inputheaderposition]); // eslint-disable-line react-hooks/exhaustive-deps

  const CheckIfValidLink = async (link) => {
    if (link !== null && link !== undefined && link !== "") {
      var p =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (link.match(p)) {
        setYoutubeLinkProp(link);
        setLinkHeaderDisplay("underline");
        setPointerEvent("auto");
      } else {
        setLinkHeaderDisplay("none");
      }
    }
  };

  const UpdateItemPropertyView = async (itemtoggle) => {
    UpdateDescriptionUI(descriptionOverflow, descriptionWhiteSpace);
    if (itemtoggle === false) {
      setItemPropertyDisplay("flex");
      setItemHRDisplay("block");
      setItemProperyToggle(true);
    } else {
      setItemPropertyDisplay("none");
      setItemHRDisplay("none");
      setItemProperyToggle(false);
    }
  };

  // const calculateImmediateSum = (quoteItem) => {
  //   console.log(quoteItem)
  //   let sum = parseFloat(quoteItem?.Price.toFixed(2)) + parseFloat(quoteItem?.LaborSubtotal.toFixed(2)); // Start with the quote item's own value
  //   if (quoteItem?.RelatedItems) {
  //     sum += quoteItem?.RelatedItems.reduce((acc, item) => {
  //       if (item?.CateType !== CategoryTypes.Labor) {
  //         return acc + (parseFloat(item?.Price.toFixed(2)) + parseFloat(item?.LaborSubtotal.toFixed(2)));
  //       }
  //       return acc;
  //     }, 0);
  //   }
  //   return sum.toFixed(2);
  // };

  const calculateImmediateSum = (quoteItem) => {
    let sum =
      parseFloat(quoteItem?.Price.toFixed(2) || 0) +
      parseFloat(quoteItem?.LaborSubtotal.toFixed(2) || 0);

    if (quoteItem?.RelatedItems) {
      sum += quoteItem.RelatedItems.reduce((acc, item) => {
        if (item?.CateType !== CategoryTypes.Labor) {
          const relatedItemSum =
            parseFloat(item?.Price.toFixed(2) || 0) +
            parseFloat(item?.LaborSubtotal.toFixed(2) || 0);
          return acc + relatedItemSum;
        }
        return acc;
      }, 0);
    }

    return sum.toFixed(2);
  };

  // Decide which header8 value to display based on itemPropertyToggle
  const header8Value = itemPropertyToggle
    ? header8 // If itemPropertyToggle is true, use the item's own header8
    : `$${calculateImmediateSum(quoteItemObj)}`; // If false, calculate the sum including related non-labor items

  useEffect(() => {
    CheckIfValidLink(itemLink);
  }, [itemLink]);

  return (
    <div>
      <StyledHRPropColour
        margin="0"
        border={
          parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
            ? `0.25px solid ${ColorPalette.PrimaryButtonBlue}`
            : `0.25px solid white`
        }
      ></StyledHRPropColour>
      <BackgroundColorDiv
        padding="10px"
        bgcolor={
          parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
            ? ColorPalette.LightGreen
            : ColorPalette.White
        }
      >
        {/* <SmallGreenLeagueText
          display={
            parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
              ? "block"
              : "none"
          }
          margin="0px"
          padding="5px"
        >
          [REQUIRED PRODUCT]
        </SmallGreenLeagueText> */}
        <StyledHRPropColour
          border="0.5px solid white"
          mobiledisplay={
            parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
              ? "none"
              : itemHRDisplay
          }
        ></StyledHRPropColour>
        <FlexRowCont100PercentSpaceBetween
          mobilewidth="98%"
          onClick={() => {
            UpdateItemPropertyView(itemPropertyToggle);
          }}
        >
          <LeagueSpartanTiny>{header2}</LeagueSpartanTiny>

          {/* <LeagueSpartanTiny>{header2}</LeagueSpartanTiny> */}
          <LeagueSpartanTiny
            onClick={() => UpdateItemPropertyView(itemPropertyToggle)}
          >
            {quoteItemObj?.RelatedItems?.length >= 1
              ? header8Value?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : header8}
          </LeagueSpartanTiny>
        </FlexRowCont100PercentSpaceBetween>
        <FlexRowCont100PercentSpaceBetween>
          <TinyTextItalics marginleft="-2px" margintop="6px">
            {subheader2notes}
          </TinyTextItalics>
        </FlexRowCont100PercentSpaceBetween>
        <FlexRowCont100PercentSpaceBetween
          onClick={() => {
            UpdateItemPropertyView(itemPropertyToggle);
          }}
        >
          <TinyHeaderDarkGrey
            display={subheader2Display}
            mobiledisplay="block"
            overflow={descriptionOverflow}
            whitespace={descriptionWhiteSpace}
          >
            {subheader2}
          </TinyHeaderDarkGrey>
        </FlexRowCont100PercentSpaceBetween>

        <StyledHRPropColour
          display={itemHRDisplay}
          mobiledisplay={itemHRDisplay}
          border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
        ></StyledHRPropColour>
        <StyledHRPropColour
          display={itemHRDisplay}
          mobiledisplay={itemHRDisplay}
          border={
            parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
              ? `0.25px solid ${ColorPalette.LightGreen}`
              : `0.25px solid white`
          }
        ></StyledHRPropColour>
        <FlexRowCont100PercentSpaceBetween display={itemPropertyDisplay}>
          <FixedItemPropertyDiv>
            <TinyHeaderDarkGrey>{header3title}</TinyHeaderDarkGrey>
            <TinyHeaderDarkGrey mobiledisplay={header3Display}>
              {header3}
            </TinyHeaderDarkGrey>
            <TextInputProp
              inputmargintop="5px"
              mobilewidth="18.5vw"
              mobiledisplay={header3InputDisplay}
              type="number"
              onBlur={(e) => {
                OutputValue(e.target.valueAsNumber);
              }}
              defaultValue={quoteItemObj?.Quant}
            ></TextInputProp>
          </FixedItemPropertyDiv>

          <FixedItemPropertyDiv>
            <TinyHeaderDarkGrey>{header4title}</TinyHeaderDarkGrey>
            <TinyHeaderDarkGrey>{header4}</TinyHeaderDarkGrey>
          </FixedItemPropertyDiv>

          <FixedItemPropertyDiv>
            <TinyHeaderDarkGrey>{header5title}</TinyHeaderDarkGrey>
            <TinyHeaderDarkGrey>{header5}</TinyHeaderDarkGrey>
          </FixedItemPropertyDiv>
        </FlexRowCont100PercentSpaceBetween>

        <StyledHRPropColour
          display={itemHRDisplay}
          mobiledisplay={itemHRDisplay}
          border={
            parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
              ? `0.25px solid ${ColorPalette.LightGreen}`
              : `0.25px solid white`
          }
        ></StyledHRPropColour>

        <FlexRowCont100PercentSpaceBetween display={itemPropertyDisplay}>
          <FixedItemPropertyDiv>
            <TinyHeaderDarkGrey></TinyHeaderDarkGrey>
          </FixedItemPropertyDiv>

          <FixedItemPropertyDiv>
            <TinyHeaderDarkGrey>{header6title}</TinyHeaderDarkGrey>
            <TinyHeaderDarkGrey>{header6}</TinyHeaderDarkGrey>
          </FixedItemPropertyDiv>

          <FixedItemPropertyDiv>
            <TinyHeaderDarkGrey>{header7title}</TinyHeaderDarkGrey>
            <TinyHeaderDarkGrey>{header7}</TinyHeaderDarkGrey>
          </FixedItemPropertyDiv>
        </FlexRowCont100PercentSpaceBetween>
      </BackgroundColorDiv>

      <StyledHRPropColour
        border={`0.5px solid ${ColorPalette.White}`}
        mobiledisplay={
          parentid !== 0 && quoteItemObj?.CateType === CategoryTypes.Material
            ? "none"
            : "block"
        }
      ></StyledHRPropColour>

      {/* {quoteItemObj?.RelatedItems?.map((o, i) => {
        if (o.CateType !== CategoryTypes.Labor) {
          return (
            <FlexColumnFullWidth
              margin="0"
              display={
                parentid !== 0 &&
                quoteItemObj?.CateType === CategoryTypes.Material
                  ? "block"
                  : itemPropertyDisplay
              }
            >
              <MobileQuoteItem
                quoteItemObj={o}
                key={i}
                parentid={o.Parent_Id}
                noChange={o.NoChange}
                header2={o.Name}
                subheader2notes={o.Notes == null ? "" : o.Notes + ""}
                subheader2={o.Description == null ? "" : o.Description}
                header3={o.Quant.toFixed(2)}
                header4={"$" + o.UnitPrice.toFixed(2)}
                header5={"$" + o.Price.toFixed(2)}
                header6={"$" + (o.LaborSubtotal / o.Quant).toFixed(2)}
                header7={"$" + o.LaborSubtotal.toFixed(2)}
                header8={
                  "$" +
                  (
                    parseFloat(o.Price.toFixed(2)) +
                    parseFloat(o.LaborSubtotal.toFixed(2))
                  ).toFixed(2)
                }
                header3title="Quantity"
                header4title="Unit Rate"
                header5title="Material Subtotal"
                header6title="Install Rate"
                header7title="Install Subtotal"
              ></MobileQuoteItem>
            </FlexColumnFullWidth>
          );
        } else {
          return null;
        }
      })} */}
      {
        itemPropertyToggle // Check if itemPropertyToggle is true
          ? quoteItemObj?.RelatedItems?.map((o, i) => {
              if (o.CateType !== CategoryTypes.Labor) {
                return (
                  <FlexColumnFullWidth
                    margin="0"
                    display={
                      parentid !== 0 &&
                      quoteItemObj?.CateType === CategoryTypes.Material
                        ? "block"
                        : itemPropertyDisplay
                    }
                  >
                    <MobileQuoteItem
                      quoteItemObj={o}
                      key={i}
                      parentid={o.Parent_Id}
                      noChange={o.NoChange}
                      header2={o.Name}
                      subheader2notes={o.Notes == null ? "" : o.Notes + ""}
                      subheader2={o.Description == null ? "" : o.Description}
                      header3={o.Quant.toFixed(2)}
                      header4={"$" + o.UnitPrice.toFixed(2)}
                      header5={"$" + o.Price.toFixed(2)}
                      header6={"$" + (o.LaborSubtotal / o.Quant).toFixed(2)}
                      header7={"$" + o.LaborSubtotal.toFixed(2)}
                      header8={
                        "$" +
                        (
                          parseFloat(o.Price.toFixed(2)) +
                          parseFloat(o.LaborSubtotal.toFixed(2))
                        ).toFixed(2)
                      }
                      header3title="Quantity"
                      header4title="Unit Rate"
                      header5title="Material Subtotal"
                      header6title="Install Rate"
                      header7title="Install Subtotal"
                    ></MobileQuoteItem>
                  </FlexColumnFullWidth>
                );
              } else {
                return null;
              }
            })
          : null // If itemPropertyToggle is false, render nothing
      }
    </div>
  );
};

MobileQuoteItem.defaultProps = {
  header1: "",
  header2: "",
  subheader2: "",
  header3: "",
  header3title: "",
  header4: "",
  header4title: "",
  header4input: "",
  header5: "",
  header5title: "",
  header6: "",
  header6title: "",
  header7: "",
  header7title: "",
  header8: "",
  bgColor: ColorPalette.White,
  inputheaderposition: 0,
  OutputValue: () => {},
  RemoveItemFromLocalQuoteObject: () => {},
};

export default MobileQuoteItem;
