import styled from "styled-components";
import { animated } from "react-spring";
import { ColorPalette } from "../Colors";

const nullProp = null;

export const PageContainer = styled.div`
position: relative;
  height:500vh;
`

export const StickyTopMenuCont = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  top: 0;
  z-index: 999;
`;

export const MenuCont = styled(animated.div)`
  height: 99vh;
  width: 17.5vw;
  margin: 1%;
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: column;
  border-left: 0px;
  border-right: 0px;
  @media (max-width: 1006px) {
    width: 101vw;
    height: 10vh;
    background-color: #333;
    position: fixed;
    bottom: 0;
    margin: 0;
    right: 0vw;
    z-index: 1000;
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "none")};
  }
`;

export const TopMenuCont = styled.div`
  height: ${(props) => (props.height ? props.height : "24vh")};
  width: 82vw;
  background-color: white;
  zindex: 2;
  border-left: 0px;
  border-right: 0px;
  @media (max-width: 1006px) {
    width: 101vw;
    height: 6vh;
    background-color: #333;
    position: fixed;
    top: 0;
    z-index: 1000;
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "none")};
  }
`;

export const TopMenuCont_AutoHeight = styled.div`
  width: 82vw;
  background-color: white;
  z-index: 2;
  border-left: 0px;
  border-right: 0px;
  @media (max-width: 1006px) {
    width: 101vw;
    height: 6vh;
    background-color: #333;
    position: fixed;
    top: 0;
    z-index: 1000;
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "none")};
  }
`;

export const TopMenuContAutoHeightSticky = styled.div`
  width: ${(props) => (props.width ? props.width : "82vw")};
  background-color: white;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 15px;
  border-left: 0px;
  border-right: 0px;
  z-index: 1000; // Add z-index for desktop view

  @media (max-width: 1006px) {
    width: 101vw;
    height: 6vh;
    background-color: #333;
    position: fixed;
    top: 0;
    z-index: 1000;
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "none")};
  }
`;


export const MenuNRCLogo = styled.img`
  width: auto;
  height: 150px;
  margin: -8%;
  cursor: pointer;
  object-fit: contain;
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
`;

export const FlexColumnCont = styled.div`
width:${(props) => (props.width ? props.width : "98%")};
height:${(props) => (props.height ? props.height : "20%")};
margin:${(props) => (props.margin ? props.margin : "1%")};
position:${(props) => (props.position ? props.position : "")};
top:${(props) => (props.top ? props.top : "")};
bottom:${(props) => (props.bottom ? props.bottom : "")};
left:${(props) => (props.left ? props.left : "")};
right:${(props) => (props.right ? props.right : "")};
border-bottom:${(props) => (props.borderbottom ? props.borderbottom : "")};
background-color:${(props) => (props.bgcolor ? props.bgcolor : "")};
align-items:${(props) => (props.alignitems ? props.alignitems : "center")};
display:${(props) => (props.display ? props.display : "flex")};
padding:${(props) => (props.padding ? props.padding : "")};
padding-left:${(props) => (props.paddingleft ? props.paddingleft : "")};
padding-bottom:${(props) => (props.paddingbottom ? props.paddingbottom : "")};
padding-right:${(props) => (props.paddingright ? props.paddingright : "")};
cursor:${(props) => (props.cursor ? props.cursor : "")};
margin-bottom:${(props) => (props.marginbottom ? props.marginbottom : "")};
margin-top:${(props) => (props.margintop ? props.margintop : "")};
border:${(props) => (props.border ? props.border : "")};
box-shadow: ${(props) => (props.boxshadow ? props.boxshadow : "")}; 
flex-direction:${(props) =>
  props.flexdirection ? props.flexdirection : "column"};
justify-content:${(props) =>
  props.justifycontent ? props.justifycontent : "center"};
margin-left:${(props) => (props.marginleft ? props.marginleft : "")};
border-radius${(props) => (props.borderradius ? props.borderradius : "")};
border-bottom:${(props) => (props.borderbottom ? props.borderbottom : "")};
overflow-y:${(props) => (props.overflowy ? props.overflowy : "")};
word-wrap:${(props) => (props.wordwrap ? props.wordwrap : "")};
z-Index:${(props) => (props.zIndex ? props.zIndex : "")};
opacity:${(props) => (props.opacity ? props.opacity : "100%")};
@media (max-width: 1528px) {
  flex-direction:${(props) =>
    props.tabletflexdirection ? props.tabletflexdirection : ""};
  align-items:${(props) =>
    props.tabletalignitems ? props.tabletalignitems : ""};
  }
  
@media (max-width: 1006px) {
  width:${(props) => (props.mobilewidth ? props.mobilewidth : "auto")};
  height:${(props) => (props.mobileheight ? props.mobileheight : "auto")};
  margin:${(props) => (props.mobilemargin ? props.mobilemargin : "")};
  border-bottom:${(props) =>
    props.mobileborderbottom ? props.mobileborderbottom : ""};
  background-color:${(props) =>
    props.mobilebgcolor ? props.mobilebgcolor : ""};
  align-items:${(props) =>
    props.mobilealignitems ? props.mobilealignitems : ""};
  display:${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
  padding:${(props) => (props.mobilepadding ? props.mobilepadding : "")};
  padding-bottom:${(props) =>
    props.mobilepaddingbottom ? props.mobilepaddingbottom : ""};
  margin-bottom:${(props) =>
    props.mobilemarginbottom ? props.mobilemarginbottom : ""};
  border:
  flex-direction:${(props) =>
    props.mobileflexdirection ? props.mobileflexdirection : ""};
  justify-content:${(props) =>
    props.mobilejustifycontent ? props.mobilejustifycontent : ""};
  margin-left:${(props) =>
    props.mobilemarginleft ? props.mobilemarginleft : ""};
  overflow-y:${(props) => (props.mobileoverflowy ? props.mobileoverflowy : "")};
  flex-direction:${(props) =>
    props.mobileflexdirection ? props.mobileflexdirection : ""};
  position:${(props) => (props.mobileposition ? props.mobileposition : "")};
  top:${(props) => (props.mobiletop ? props.mobiletop : "")};
  bottom:${(props) => (props.mobilebottom ? props.mobilebottom : "")};
  left:${(props) => (props.mobileleft ? props.mobileleft : "")};
  right:${(props) => (props.mobileright ? props.mobileright : "")};
}
`;

export const FlexColumnContBoxShadow = styled.div`
  width: ${(props) => (props.width ? props.width : "70%")};
  height: ${(props) => (props.height ? props.height : "20%")};
  margin: ${(props) => (props.margin ? props.margin : "1%")};
  padding: 2%;
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexdirection ? props.flexdirection : "column"};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  opacity: ${(props) => (props.opacity ? props.opacity : "100%")};
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 8px 10px ${ColorPalette.PrimaryGrey};
  @media (max-width: 1006px) {
    height: ${(props) => (props.mobileheight ? props.mobileheight : "auto")};
    flex-direction: ${(props) =>
      props.mobileflexdirection ? props.mobileflexdirection : ""};
    border: ${(props) => (props.mobileborder ? props.mobileborder : "")};
  }
`;

export const FlexColumnContBoxShadow19Percent = styled.div`
  width: 19%;
  height: 20%;
  margin: 1%
  padding: 2%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 100%;
  text-align: center;
  border-radius: 20px;
  @media (max-width: 1006px) {
    height: auto;
  }
`;

export const Icon = styled.img`
  width: ${(props) => (props.width ? props.width : "35%")};
  height: ${(props) => (props.height ? props.height : "50%")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "100px"};
  object-fit: ${(props) => (props.objfit ? props.objfit : "")};
  @media (max-width: 1006px) {
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
  }
`;

export const CircularImage125px = styled.img`
  width: ${(props) => (props.width ? props.width : "125px")};
  height: ${(props) => (props.height ? props.height : "125px")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "100px"};
  object-fit: ${(props) => (props.objfit ? props.objfit : "cover")};
  margin-bottom: 10px;
  @media (max-width: 1006px) {
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
  }
`;

export const Icon20x80Percent = styled.img`
  width: 20%;
  height: 80%;
  border-radius: 100px;
`;

export const MobileIcon100x130Percent = styled.img`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "130%")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : "100px"};
  object-fit: ${(props) => (props.objfit ? props.objfit : "")};
  @media (max-width: 1006px) {
    margin-top: 5px;
    margin-bottom: -10px;
    height: ${(props) => (props.mobileheight ? props.mobileheight : "30px")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "100%")};
  }
`;

export const SmallHeader = styled.p`
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "Avenir")};
  padding-bottom: ${(props) =>
    props.paddingbottom ? props.paddingbottom : ""};
  color: ${(props) => (props.color ? props.color : "black")};
  margin: ${(props) => (props.margin ? props.margin : "5px")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  border-bottom: ${(props) => (props.borderbottom ? props.borderbottom : "")};
  display: ${(props) => (props.display ? props.display : "block")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  border-radius: 3px;
  text-decoration: ${(props) =>
    props.textdecoration ? props.textdecoration : ""};
  transition: color 0.5s;
  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : "")};
    background-color: ${(props) =>
      props.hoverbgcolor ? props.hoverbgcolor : ""};
    border: ${(props) => (props.hoverborder ? props.hoverborder : "")};
  }
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "12px"};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
    border-bottom: ${(props) =>
      props.mobileborderbottom ? props.mobileborderbottom : ""};
  }
`;

export const TinyHeader = styled.p`
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  font-size: ${(props) => (props.fontsize ? props.fontsize : "12px")};
  font-family: ${(props) => (props.fontfamily ? props.fontfamily : "Avenir")};
  padding-bottom: ${(props) =>
    props.paddingbottom ? props.paddingbottom : ""};
  color: ${(props) => (props.color ? props.color : "black")};
  margin: ${(props) => (props.margin ? props.margin : "5px")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  border-bottom: ${(props) => (props.borderbottom ? props.borderbottom : "")};
  display: ${(props) => (props.display ? props.display : "block")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  border-radius: 3px;
  text-decoration: ${(props) =>
    props.textdecoration ? props.textdecoration : ""};
  transition: color 0.5s;
  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : "")};
    background-color: ${(props) =>
      props.hoverbgcolor ? props.hoverbgcolor : ""};
    border: ${(props) => (props.hoverborder ? props.hoverborder : "")};
  }
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
    font-size: ${(props) =>
      props.mobilefontsize ? props.mobilefontsize : "12px"};
    height: ${(props) => (props.mobileheight ? props.mobileheight : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
    border-bottom: ${(props) =>
      props.mobileborderbottom ? props.mobileborderbottom : ""};
  }
`;

export const StyledHR = styled.hr`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "0%")};
  border: ${(props) => (props.border ? props.border : "1px solid black")};
  position: ${(props) => (props.position ? props.position : "")};
  bottom: ${(props) => (props.bottom ? props.bottom : "")};
  top: ${(props) => (props.top ? props.top : "")};
  left: ${(props) => (props.left ? props.left : "")};
  right: ${(props) => (props.right ? props.right : "")};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  @media (max-width: 1006px) {
    display: ${(props) =>
      props.mobiledisplay ? props.mobiledisplay : "block"};
  }
`;

export default nullProp;
